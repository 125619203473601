.center-contain-bg {
	background-position: center center;
	background-size: contain;
	background-repeat: no-repeat;
}

.check-svg {
	background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIGZpbGw9Im5vbmUiIHZpZXdCb3g9IjAgMCA0MSA0MSI+PGRlZnMvPjxnIGNsaXAtcGF0aD0idXJsKCNjbGlwMCkiPjxwYXRoIGZpbGw9InVybCgjcGFpbnQwX2xpbmVhcikiIGQ9Ik00MC4zIDIwLjNhMjAgMjAgMCAxMS00MCAwIDIwIDIwIDAgMDE0MCAwek0xOCAzMUwzMyAxNi4xYy41LS41LjUtMS4zIDAtMS44TDMxIDEyLjRjLS41LS41LTEuMy0uNS0xLjggMGwtMTIgMTIuMi01LjctNS43Yy0uNS0uNS0xLjQtLjUtMS45IDBsLTEuOCAxLjhjLS41LjUtLjUgMS4zIDAgMS45bDguNCA4LjNjLjUuNSAxLjMuNSAxLjggMHoiLz48L2c+PGRlZnM+PGxpbmVhckdyYWRpZW50IGlkPSJwYWludDBfbGluZWFyIiB4MT0iLjMiIHgyPSI0MC4zIiB5MT0iMjAuMyIgeTI9IjIwLjMiIGdyYWRpZW50VW5pdHM9InVzZXJTcGFjZU9uVXNlIj48c3RvcCBzdG9wLWNvbG9yPSIjNjNDNThDIi8+PHN0b3Agb2Zmc2V0PSIxIiBzdG9wLWNvbG9yPSIjNDFCQ0E4Ii8+PC9saW5lYXJHcmFkaWVudD48Y2xpcFBhdGggaWQ9ImNsaXAwIj48cGF0aCBmaWxsPSIjZmZmIiBkPSJNMCAwaDQwdjQwSDB6IiB0cmFuc2Zvcm09InRyYW5zbGF0ZSguMyAuMykiLz48L2NsaXBQYXRoPjwvZGVmcz48L3N2Zz4=");
	@extend .center-contain-bg;
}

.arrow-right {
	background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIGZpbGw9Im5vbmUiIHZpZXdCb3g9IjAgMCA4IDEyIj48ZGVmcy8+PHBhdGggZmlsbD0iIzQzQkRBNyIgZmlsbC1ydWxlPSJldmVub2RkIiBkPSJNMy44IDZMLjMgMi42IDIuNC40IDggNmwtNS42IDUuNi0yLTIuMkwzLjcgNnoiIGNsaXAtcnVsZT0iZXZlbm9kZCIvPjwvc3ZnPg==");
	@extend .center-contain-bg;
}

.arrow-down {
	background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIGZpbGw9Im5vbmUiIHZpZXdCb3g9IjAgMCAxNiAxMiI+PGRlZnMvPjxwYXRoIGZpbGw9IiM0M0JEQTciIGZpbGwtcnVsZT0iZXZlbm9kZCIgZD0iTS42IDEuMmEyIDIgMCAwMTIuOCAwTDggNS44bDQuNi00LjZBMiAyIDAgMTExNS40IDRMOCAxMS41LjYgNGEyIDIgMCAwMTAtMi44eiIgY2xpcC1ydWxlPSJldmVub2RkIi8+PC9zdmc+");
	@extend .center-contain-bg;
}

.arrow-down-white {
	background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTIiIGhlaWdodD0iOSIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGNsaXAtcnVsZT0iZXZlbm9kZCIgZD0iTS45NCAzLjA2TDMuMDYuOTQgNiAzLjg3OCA4Ljk0LjkzOWwyLjEyIDIuMTIyTDYgOC4xMi45NCAzLjA2MXoiIGZpbGw9IiNmZmYiLz48L3N2Zz4=");
	@extend .center-contain-bg;
}
