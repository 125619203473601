.play-btn {
    $r: &;
    $transitionDuration: 0.3s;
    $borderWidth: 1px;
    $backBorderColor: $white;
    $drawBorderColor: $green;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    cursor: pointer;
    z-index: 10;

    &__container {
        position: relative;
        display: block;
        width: 230px;
        z-index: 2;
        @include rh;

        &::before {
            content: "";
            @include absolute;
            width: 100%;
            height: 100%;
            display: block;
            border: $borderWidth solid $backBorderColor;
            box-sizing: border-box;
            border-radius: 50%;
            z-index: 1;
        }
    }

    &__play-icon {
        @include absolute();
        width: 50px;
        height: 58px;
        transform: translateX(16%);
        
        &::before {
            content: '';
            display: block;
            width: 100%;
            height: 100%;
            transition: .3s ease-in-out;
            background: url('../images/svg/play.svg') center center no-repeat;
            background-size: contain;
            will-change: transform;
        }
    }

    &__border-col {
        width: 50%;
        height: 100%;
        display: block;
        overflow: hidden;
        z-index: 5;

        &::before {
            content: "";
            @include absolute;
            width: 200%;
            height: 100%;
            display: block;
            border: $borderWidth solid $drawBorderColor;
            box-sizing: border-box;
            border-radius: 50%;
            transition: transform $transitionDuration linear;
        }

        &_left {
            @include absolute(0, auto, auto, 0);

            &::before {
                @include absolute(0, auto, auto, 0);
                border-right: $borderWidth solid transparent;
                border-bottom: $borderWidth solid transparent;
                transform: rotate(-225deg);
                transition-delay: 0s;
            }
        }

        &_right {
            @include absolute(0, 0, auto, auto);

            &::before {
                @include absolute(0, 0, auto, auto);
                border-left: $borderWidth solid transparent;
                border-top: $borderWidth solid transparent;
                transform: rotate(-225deg);
                transition-delay: $transitionDuration;
            }
        }
    }

    &:hover & {
        &__play-icon {
            &::before {
                transform: scale(1.05);
            }
        }
        
        &__border-col {
            &::before {
                transform: rotate(-45deg);
            }

            &_left {
                &::before {
                    transition-delay: $transitionDuration;
                }
            }

            &_right {
                &::before {
                    transition-delay: 0s;
                }
            }
        }
    }
    
    
    @include media_1500 {
        &__container {
            width: 160px;
        }
    
    }
    
    @include media_1300 {
        &__container {
            width: 103px;
        }
    }
    
    @include media_900 {
    
        &__play-icon {
            width: 30px;
            height: 36px;
        }
    }
    
    @include media_700 {
        &__container::before,
        &__border-col {
            display: none;
        }
    }
}
