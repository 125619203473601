@mixin input-wrap {
    width: 100%;
    position: relative;
    margin-bottom: 20px;
}

@mixin error-styles {
    &.error,
    .pseudo-hidden.error,
    &.pseudo-hidden.error {
        & ~ .select-module__label,
        & ~ .input-style__label {
            color: red;
        }
    }
}

@mixin input-style {
    background: rgba(255, 255, 255, 0.1);
    border-radius: 5px;
    border: 1px solid $white;
    position: relative;
    z-index: 9;
    transition: $default_transition;
    height: 60px;
    width: 100%;
    padding: 0 30px;
    box-sizing: border-box;
    display: block;
    font-family: $metropolis;
    @include p3;
    @include error-styles;
    color: $white;
    
    @include media_1700 {
    
    }
    
    @include media_1300 {
        height: 50px;
    }
    
    @include media_1000 {
    
    }
    
    @include media_700 {
    
    }
    
}

.input-style {
    @include input-wrap;

    &__input {
        @include input-style;
    }
    
    &__container {
        display: flex;
        flex-direction: column-reverse;
        align-items: flex-start;
        justify-content: flex-start;
    }
    
    &__label {
        color: $white;
    }

    &_textarea & {
        &__input {
            height: 130px;
            transition: none;
            padding-top: 15px;
            overflow: hidden;
        }
    }
    
    p {
        display: block;
        
        span {
            display: block;
        }
    }
    
    br {
        display: none;
    }
    
    [class*="form-control-wrap"] {
        display: block;
        position: relative;
        width: 100%;
    }
}

.select-wrapper {

}