.header {
    z-index: 90;
    display: block;
    position: relative;
    box-sizing: border-box;
    width: 100%;
    padding: 25px 0;
    
    &__sticky-wrap {
        width: 100%;
        overflow: visible !important;
        display: block;
        position: relative;
    }
    
    &__container {
        position: relative;
        background: $white;
        
        &::before {
            content: '';
            display: block;
            position: absolute;
            top: 0;
            right: 0;
            bottom: auto;
            left: 50%;
            transform: translateX(-50%);
            margin-bottom: auto;
            margin-left: 0;
            margin-right: 0;
            width: 100vw;
            height: 360px;
            background: $gradient5;
            @extend .mtr60;
            transition: opacity .2s ease;
            opacity: 0;
            pointer-events: none;
            z-index: 1;
        }
        
        &::after {
            content: '';
            display: block;
            width: 100vw;
            height: 100%;
            background: $white;
            @include absolute(0,auto,0,50%);
            transform: translateX(-50%);
            pointer-events: none;
            z-index: 0;
        }
        
        &._sticky {
            @include fixed(0,0,auto);
            padding-top: 10px;
            padding-bottom: 10px;
            z-index: 20;
            
            &::before {
                height: 160px;
                margin-top: 0;
            }
        }
    }
    
    &__main {
        position: relative;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        z-index: 2;
    }
    
    &__btn-wrap {
        
    }
    
    &__logo-wrap {
        position: relative;
        z-index: 10;
        margin-right: auto;
        padding-right: 40px;
    }
    
    &__hamburger-wrap {
        position: relative;
        z-index: 10;
    }
    
    &__menu-container {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        align-self: stretch;
        margin-right: 50px;
        z-index: 1;
    }
    
    &__search {
        position: relative;
        padding-top: 20px;
        padding-bottom: 30px;
        display: none;
        z-index: 2;
        
        .search-btn {
            padding: 0 17px;
        }
    }
    
    &__search-btn-wrap {
        margin-right: 50px;
    }
    
    &__search-wrap {
        flex: 1 0 0;
    }
    
    &__search-inner {
        display: flex;
        align-items: center;
        justify-content: flex-start;
    }
    
    &._search-active & {
        &__container {
            &::before {
                opacity: 1;
            }
        }
    }
    
    &._search-active {
        .search-btn_show {
            pointer-events: none;
            opacity: 0;
        }
    }
    
    @include media_1700 {
        &__search-btn-wrap {
            margin-right: 30px;
        }
    }
    
    @include media_1200 {
        &__scroll-wrap {
            height: 100%;
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: flex-start;
            
            .mCSB_container {
                text-align: center;
                padding-left: 10px;
            }
            
            .mCustomScrollBox {
                width: 100%;
                height: auto;
                max-height: 100%;
                margin: auto;
            }
        }
        
        
        &__menu-wrap {
            transition: top, opacity;
            transition-duration: 0s, 1s;
            transition-timing-function: linear, ease;
            transition-delay: 1s, 0s;
            opacity: 0;
            pointer-events: none;
            background: $gray5;
        }
        
        &._active & {
            &__menu-wrap {
                pointer-events: auto;
                transition-delay: 0s, 0s;
                top: 100%;
                opacity: 1;
            }
        }
        
    }
    
    @include media_1200-700 {
        &__scroll-wrap {
            max-height: 240px;
        }
        
        &__menu-wrap {
            @include absolute(-150vh,auto,auto,50%);
            transform: translateX(-50%);
            width: 300px;
            height: auto;
            max-height: 300px;
            background: $gray5;
            border-radius: 20px;
            padding: 30px;
        }
    }
    
    @include media_900 {
        &__menu-container {
            margin-right: 30px;
        }
        
        &__search-btn-wrap {
            margin-right: 30px;
        }
    }
    
    @include media_700 {
        &__menu-container {
            margin-right: 0;
        }
        
        &__scroll-wrap {
            padding-top: 20px;
            
            .mCSB_container {
                padding: 20px 0 80px 40px;
                margin-right: 40px;
            }
            
            .mCustomScrollBox .mCSB_container.mCS_y_hidden {
                margin-right: 40px;
            }
        }
        
        &__menu-wrap {
            @include absolute(0,-40px,auto,auto);
            width: 100vw;
            height: calc(100vh - 50px);
            
            &::before {
                content: '';
                width: 100%;
                height: 50vh;
                background: $gray5;
                @include absolute(auto, 0, 100%);
            }
        }
    }
    
    @include media_500 {
        &__menu-wrap {
            right: -25px;
        }
    }
}

.search-btn {
    cursor: pointer;
    transition: $default-transition;
    
    &::before {
        font-size: 18px;
        line-height: 1;
        color: $green;
        transition: $default-transition;
    }
    
    &:hover {
        &::before {
            color: $dark;
        }
    }
}

.nav {
    text-align: left;
    
    a {
        display: block;
        @extend .link-style;
        z-index: 2;
    }
    
    ul {
        position: relative;
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
    
        li {
            position: relative;
            display: block;
            z-index: 2;
            margin-right: 30px;
    
            &:last-child {
                margin-right: 0;
                margin-bottom: 0;
            }
    
            ul {
                flex-direction: column;
                padding: 20px 0 0 15px;
        
                &::before {
                    content: '';
                    @include absolute(auto, auto);
                    height: calc(100% - 10px);
                    background: $green;
                    width: 1px;
                    left: 0;
                    z-index: 1;
                }
    
                li {
                    margin-bottom: 10px;
                    margin-right: 0;
                }
            }
        }
    }
    
    &_dropdown {
        @include media_min-1200 {
            & > ul {
                & > li {
                    &:hover {
                        z-index: 3;
        
                        & > ul {
                            transform: translateY(0);
                            opacity: 1;
                            pointer-events: auto;
                        }
                    }
                    
                    & > ul {
                        @include absolute(99%, auto, auto);
                        transform: translateY(10px);
                        opacity: 0;
                        transition: $default-transition;
                        pointer-events: none;
                        padding: 30px 20px 20px;
                        width: max-content;
                        max-width: 300px;
    
                        &::before {
                            width: 100%;
                            background: $gray5;
                        }
                    }
                }
            }
        }
    }
    
    @include media_1700 {
        ul {
            li {
                margin-right: 20px;
            }
        }
    }

    @include media_1200 {
        &_dropdown {
            & > ul {
                flex-direction: column;
                align-items: flex-start;
                justify-content: flex-start;
        
                & > li {
                    margin-right: 0;
                    margin-bottom: 10px;
            
                    &:last-child {
                        margin-bottom: 0;
                    }
            
                }
            }
        }
    }

    @include media_700 {
        & > ul {
            flex-direction: column;
            align-items: flex-start;
            justify-content: flex-start;
        
            & > li {
                margin-right: 0;
                margin-bottom: 10px;
            
                &:last-child {
                    margin-bottom: 0;
                }
            
            }
        }
        
        &_dropdown {
            margin: 40px 0;
        }
    }
}
