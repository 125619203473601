@keyframes preloader-scale {
    0%   {
        transform:  scale(.8);
    }
    50% {
        transform:  scale(1);
    }
    100% {
        transform:  scale(.8);
    }
}

@keyframes spin {
    0%   {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.form-style,
.form-preloader {
    &:not(._waiting) {
        .preloader-wrap {
            display: none;
        }
        
        .preloader,
        .spinner {
            animation-play-state: paused;
        }
    }
    
    &._waiting {
        pointer-events: none;
        
        & > *:not(.preloader-wrap) {
            opacity: .3;
        }
    }
}

.preloader-wrap {
    @include absolute;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 90;
}

.preloader {
    position: relative;
    display: block;
    width: 150px;
    @include rh;
    max-width: 60%;
    transform: scale(.8);
    $border-width: 2px;
    $color-big: $white;
    $color-med: $white;
    $color-small: $white;
    animation: preloader-scale 6s linear infinite;
    
    .spinner {
        @include absolute;
        width: 100%;
        height: 100%;
        border-radius: 50%;
        border: $border-width solid transparent;
        border-top-color: $color-big;
        animation: spin 2s linear infinite;

        &::before {
            content: "";
            @include absolute;
            width: calc(100% - 10px);
            height: calc(100% - 10px);
            
            border-radius: 50%;
            border: $border-width solid transparent;
            border-top-color: $color-med;
            animation: spin 3s linear infinite;
        }

        &::after {
            content: "";
            
            @include absolute;
            width: calc(100% - 20px);
            height: calc(100% - 20px);

            border-radius: 50%;
            border: $border-width solid transparent;
            border-top-color: $color-small;
            animation: spin 1.5s linear infinite;
        }
    }
}
