body {
    font-family: $metropolis;
    color: $dark;
    font-weight: 400;
}

svg {
    overflow: visible;
    pointer-events: none;
    backface-visibility: hidden;
}

section {
    position: relative;
    z-index: 2;
}

picture {
    display: block;
    position: relative;
    width: 100%;
    height: auto;
    
    img {
        display: block;
        position: relative;
        width: 100%;
        height: auto;
    }
}

._ta-center {
    text-align: center;
}

._ta-left {
    text-align: left;
}

._ta-right {
    text-align: right;
}

.pseudo-hidden {
    position: absolute;
    left: -200vw;
    opacity: 0;
    z-index: -999;
    pointer-events: none;
    overflow: hidden;
    transform: scale(0);
}

.content {
    position: relative;
    flex: 1 0 auto;
    display: block;
    width: 100%;
    overflow: hidden;
    z-index: 2;
}

.container {
    margin: 0 auto;
    width: 100%;
    max-width: 1920px;
    padding-left: 65px;
    padding-right: 65px;
    box-sizing: border-box;
    z-index: 5;
    position: relative;
    
    
    @include media_1700 {
        padding-left: 50px;
        padding-right: 50px;
    }
    
    @include media_1300 {
        padding-left: 40px;
        padding-right: 40px;
    }
    
    @include media_1000 {
        padding-left: 30px;
        padding-right: 30px;
    }
    
    @include media_700 {
        padding-left: 20px;
        padding-right: 20px;
    }
    
    @include media_500 {
        padding-left: 10px;
        padding-right: 10px;
    }
}

.inner-container {
    position: relative;
    width: 100%;
    padding-left: 115px;
    padding-right: 115px;
    
    @include media_1700 {
        padding-left: 70px;
        padding-right: 70px;
    }
    
    @include media_1300 {
        padding-left: 50px;
        padding-right: 50px;
    }
    
    @include media_1000 {
        padding-left: 30px;
        padding-right: 30px;
    }
    
    @include media_700 {
        padding-left: 20px;
        padding-right: 20px;
    }
    
    @include media_500 {
        padding-left: 15px;
        padding-right: 15px;
    }
}


.narrow-container {
    position: relative;
    width: calc(100% / 12 * 10);
    margin-left: auto;
    margin-right: auto;
    
    @include media_1000 {
        width: 100%;
    }
}


.responsive-container {
    position: relative;
    width: calc(100% / 12 * 10);
    margin: 0 auto;
    
    & & {
        width: calc(100% / 10 * 8);
    }
    
    @include media_1000 {
        width: 100%;
        
        & & {
            width: 100%;
        }
    }
}
