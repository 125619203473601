.mCustomScrollBox {
	.mCSB_container {
		margin-right: 20px;

		&.mCS_y_hidden {
			margin-right: 20px;
		}
	}

	.mCSB_scrollTools {
		width: 4px;
		right: 6px;
		opacity: 1;

		.mCSB_draggerContainer {
			.mCSB_draggerRail {
				background: transparent;
				width: 4px;
			}

			.mCSB_dragger {

				.mCSB_dragger_bar {
					width: 4px;
					margin: 0;
					background: $white;
				}
			}
		}
	}
}