.img-list {
	position: relative;
	width: calc(100% + 96px);
	left: -48px;
	margin-top: -90px;
	display: flex;
	align-items: flex-start;
	justify-content: flex-start;
	flex-wrap: wrap;
	@extend .mb100;
	
	&:last-child {
		margin-bottom: 0;
	}
	
	img {
		width: calc(50% - 96px);
		height: auto;
		margin-left: 48px;
		margin-right: 48px;
		border-radius: 10px;
		margin-top: 90px;
	}
	
	
	@include media_1500 {
		width: calc(100% + 90px);
		left: -45px;
		
		img {
			width: calc(50% - 90px);
			margin-left: 45px;
			margin-right: 45px;
		}
	}
	
	@include media_1300 {
		width: calc(100% + 54px);
		left: -27px;
		margin-top: -50px;
		
		img {
			width: calc(50% - 54px);
			margin-left: 27px;
			margin-right: 27px;
			margin-top: 50px;
		}
	}
	
	@include media_900 {
		width: calc(100% + 46px);
		left: -23px;
		margin-top: -45px;
		
		img {
			width: calc(50% - 46px);
			margin-left: 23px;
			margin-right: 23px;
			margin-top: 45px;
		}
	}
	
	@include media_700 {
		width: 100%;
		left: 0;
		margin-top: -20px;
		
		img {
			width: 100%;
			margin-left: 0;
			margin-right: 0;
			margin-top: 20px;
		}
	}
}