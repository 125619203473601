.blog {
	&__inner {
		background: $gradient2;
	}
}

.blog-list {
	$r: &;
	position: relative;
	width: calc(100% + 20px);
	left: -10px;
	margin-top: -20px;
	display: flex;
	align-items: flex-start;
	justify-content: flex-start;
	flex-wrap: wrap;
	
	&__item {
		width: calc(100% - 20px);
		margin: 20px 10px 0;
		border-radius: 10px;
		overflow: hidden;
		
		& * {
			display: block;
		}
		
		&._shadow {
			box-shadow: $box-shadow-4;
		}
		
		&._1-2 {
			width: calc(50% - 20px);
			
			#{$r}__description {
				padding-left: 85px;
				padding-right: 85px;
			}
		}
		
		&._1-4 {
			width: calc(25% - 20px);
			
			#{$r}__description {
				padding-left: 50px;
				padding-right: 50px;
			}
		}
		
		&:hover {
			#{$r}__img {
				transform: scale(1.02);
			}
		}
	}
	
	&__img-wrap {
		position: relative;
		overflow: hidden;
		width: 100%;
		height: 360px;
	}
	
	&__img {
		transition: $default-transition;
		transform-origin: 50% 50%;
		will-change: transform;
		backface-visibility: hidden;
	}
	
	&__description {
		background: $white;
	}
	
	&__title {
		color: $green;
	}
	
	&__date {
		color: $gray3;
	}
	
	&__text {
		color: $dark;
	}
	
	@include media_1700 {
		&__item {
			&._1-2 {
				#{$r}__description {
					padding-left: 40px;
					padding-right: 40px;
				}
			}
			
			&._1-4 {
				#{$r}__description {
					padding-left: 20px;
					padding-right: 20px;
				}
			}
		}
		
		&__img-wrap {
			height: 260px;
		}
		
	}
	
	@include media_1300 {
		&__item {
			&._1-2 {
				width: calc(100% / 3 - 20px);
				
				#{$r}__description {
					padding-left: 20px;
					padding-right: 20px;
				}
			}
			
			&._1-4 {
				width: calc(100% / 3 - 20px);
			}
		}
		
		&__img-wrap {
			height: 210px;
		}
		
	}
	
	@include media_1000 {
		
		&__item {
			&._1-2 {
				width: calc(100% - 20px);
			}
			
			&._1-4 {
				width: calc(50% - 20px);
			}
		}
	}
	
	@include media_700 {
	
	}
	
	@include media_500 {
		&__item {
			&._1-2,
			&._1-4 {
				width: calc(100% - 20px);
			}
		}
		
		&__img-wrap {
			height: 180px;
		}
	}
}