$metropolis: 'metropolis';
$icomoon: 'icomoon';

@font-face {
    font-family: $metropolis;
    src: url("../fonts/metropolis-webfont.woff2") format("woff2"), url("../fonts/metropolis-webfont.woff") format("woff"), url("../fonts/metropolis-webfont.ttf") format("truetype");
    font-weight: 400;
    font-style: normal;
    font-display: block;
}

@font-face {
    font-family: $metropolis;
    src: url("../fonts/metropolissemibold-webfont.woff2") format("woff2"), url("../fonts/metropolissemibold-webfont.woff") format("woff"), url("../fonts/metropolissemibold-webfont.ttf") format("truetype");
    font-weight: 600;
    font-style: normal;
    font-display: block;
}

@font-face {
    font-family: $metropolis;
    src: url("../fonts/metropolisbold-webfont.woff2") format("woff2"), url("../fonts/metropolisbold-webfont.woff") format("woff"), url("../fonts/metropolisbold-webfont.ttf") format("truetype");
    font-weight: 700;
    font-style: normal;
    font-display: block;
}


@font-face {
    font-family: $icomoon;
    src:  url('../fonts/icomoon.eot?clxphw#iefix') format('embedded-opentype'),
    url('../fonts/icomoon.ttf?clxphw') format('truetype'),
    url('../fonts/icomoon.woff?clxphw') format('woff'),
    url('../fonts/icomoon.svg?clxphw#icomoon') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
    &::before {
        font-family: $icomoon !important;
        speak: none;
        font-style: normal;
        font-weight: normal;
        font-variant: normal;
        text-transform: none;
        line-height: 1;
        
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }
}

.icon-angle-down:before {
    content: "\e900";
}
.icon-angle-left:before {
    content: "\e901";
}
.icon-angle-right:before {
    content: "\e902";
}
.icon-arrow-left:before {
    content: "\e903";
}
.icon-arrow-right:before {
    content: "\e904";
}
.icon-check:before {
    content: "\e905";
}
.icon-check-circle:before {
    content: "\e906";
}
.icon-cross:before {
    content: "\e907";
}
.icon-fb:before {
    content: "\e908";
}
.icon-instagram:before {
    content: "\e909";
}
.icon-search:before {
    content: "\e90a";
}
.icon-telegram:before {
    content: "\e90b";
}
.icon-vk:before {
    content: "\e90c";
}
.icon-leaf1:before {
    content: "\e90d";
}
.icon-leaf2:before {
    content: "\e90e";
}
.icon-leaf3:before {
    content: "\e90f";
}
.icon-quotes:before {
    content: "\e910";
}
.icon-arrow-smooth-left:before {
    content: "\e911";
}
.icon-arrow-smooth-right:before {
    content: "\e912";
}
