.feedback {
    &__wrap {
        background: $gradient3;
        margin-bottom: 210px;
    }
    
    &__img-wrap {
        @include absolute-cm(calc(100% - 60px), 0, auto);
        width: calc(100% / 12 * 10);
        max-width: 1200px;
        margin-left: auto;
        margin-right: auto;
        @include rh(464/1526);
        
        svg {
            @extend .abs-fit-svg;
        }
    }
    
    @include media_1300 {
        &__wrap {
            margin-bottom: 190px;
        }
    }
    
    @include media_1000 {
        &__img-wrap {
            top: calc(100% + 20px);
        }
    }
    
    @include media_700 {
        &__wrap {
            margin-bottom: 190px;
        }
    }
    
    @include media_500 {
        &__img-wrap {
            width: 120%;
            left: -10%;
        }
    }
}

.feedback-table {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    
    &__col {
        &_left {
            width: calc(50% - 80px);
        }
        
        &_right {
            width: calc(50% - 10px);
        }
    }
    
    &__map-container {
        width: 100%;
        position: relative;
        overflow: hidden;
        height: 600px;
        border-radius: 10px;
    }
    
    &__map {
        height: 100%;
        width: 100%;
    }
    
    @include media_1700 {
    
    }
    
    @include media_1300 {
        &__col {
            &_right {
                width: calc(50% + 40px);
            }
        }
    
        &__map-container {
            height: 500px;
        }
    }
    
    @include media_1000 {
        flex-direction: column-reverse;
        
        &__col {
            width: 100%;
            
            &_left {
                margin-top: 20px;
            }
        }
        &__map-container {
            height: 400px;
        }
    }
    
    @include media_700 {
        &__map-container {
            height: 300px;
        }
    }
}

@keyframes bot-flame {
    0% {
       transform: translate3d(0,0,0);
    }
    
    50% {
       transform: translate3d(0,-1%,0);
    }
    
    100% {
       transform: translate3d(0,0,0);
    }
}

@keyframes move-bot {
    0% {
       transform: translate3d(0,0,0);
    }
    
    50% {
       transform: translate3d(0,2%,0);
    }
    
    100% {
       transform: translate3d(0,0,0);
    }
}

.feedback-bot {
    animation: move-bot 2s ease-in-out infinite;
}

.feedback-bot-flame {
    animation: bot-flame 2s ease-in-out infinite;
}

.feedback-bot-flame-2 {
    animation-delay: .2s;
}



@keyframes feedback-bot-eye {
    0% {
        transform: scaleY(1);
    }
    
    90% {
        transform: scaleY(1);
    }
    
    95% {
        transform: scaleY(0.1);
    }
    
    100% {
        transform: scaleY(1);
    }
}

.feedback-bot-eye {
    transform-box: fill-box;
    transform-origin: 50% 50%;
    animation: feedback-bot-eye 4s ease-in-out infinite;
}

@keyframes move-bot {
    0% {
       transform: translate3d(0,0,0);
    }
    
    50% {
       transform: translate3d(0,-2%,0);
    }
    
    100% {
       transform: translate3d(0,0,0);
    }
}
