.pagination {
	@extend .b1;
	
	.nav-links {
		display: flex;
		align-items: center;
		justify-content: center;

		.page-numbers {
			width: 60px;
			height: 60px;
			border-radius: 50%;
			color: $green;
			transition: $default-transition;
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			
			&.prev,
			&.next,
			&.dots {
				width: auto;
				height: auto;
				border-radius: 0;
			}
			
			&.prev,
			&.next {

				&::before {
					font-family: $icomoon;
					font-size: 20px;
				}
			}

			&.prev {
				margin-right: 24px;

				&::before {
					content: '\e903';
				}
			}

			&.next {
				margin-left: 24px;

				&::before {
					content: '\e904';
				}
			}

			&:hover,
			&.current {
				color: $dark;
			}
			
			&.current {
				background: $white;
			}

			&.dots {
				margin: 0 20px;
				
				&:hover {
					color: $green;
				}
			}
		}
	}
	
	@include media_700 {
		.nav-links {
			.page-numbers {
				width: 30px;
				height: 30px;
				
				&.prev,
				&.next {
					&::before {
						font-size: 14px;
					}
				}
				
				&.prev {
					margin-right: 14px;
				}
				
				&.next {
					margin-left: 14px;
				}
	
				&.dots {
					margin: 0 5px;
				}
			}
		}
	}
}


