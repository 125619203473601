.h1 {
    font-weight: 600;
    font-size: 72px;
    line-height: 1;
    
    @include media_1700 {
        font-size: 59px;
    }
    
    @include media_1300 {
        font-size: 45px;
    }
    
    @include media_1000 {
        font-size: 36px;
    }
    
    @include media_700 {
        font-size: 24px;
    }
}

.h2 {
    font-weight: 600;
    font-size: 48px;
    line-height: 1;
    
    @include media_1700 {
        font-size: 42px;
    }
    
    @include media_1300 {
        font-size: 36px;
    }
    
    @include media_1000 {
        font-size: 30px;
    }
    
    @include media_700 {
        font-size: 24px;
    }
}


.h2-big {
    font-family: $metropolis;
    font-weight: 800;
    font-size: 60px;
    line-height: 1;
    
    @include media_1500 {
        font-size: 50px;
    }
    
    @include media_1300 {
        font-size: 44px;
    }
    
    @include media_900 {
        font-size: 40px;
    }
    
    @include media_700 {
        font-size: 25px;
    }
}

.h3 {
    font-weight: 600;
    font-size: 30px;
    line-height: 1;
    
    @include media_1700 {
        font-size: 27px;
    }
    
    @include media_1300 {
        font-size: 24px;
    }
    
    @include media_1000 {
        font-size: 21px;
    }
    
    @include media_700 {
        font-size: 18px;
    }
}


.h4 {
    font-family: $metropolis;
    font-weight: 800;
    font-size: 24px;
    line-height: 1.7;
    
    @include media_1500 {
        font-size: 20px;
    }
    
    @include media_900 {
        font-size: 18px;
    }
    
    @include media_700 {
        font-size: 16px;
    }
}

.h5 {
    font-family: $metropolis;
    font-weight: 800;
    font-size: 20px;
    line-height: 1.7;
    
    @include media_1500 {
        font-size: 18px;
    }
    
    @include media_700 {
        font-size: 16px;
    }
}

.p1 {
    font-size: 20px;
    line-height: 1.7;
    
    @include media_1700 {
        font-size: 18px;
    }
    
    @include media_1300 {
        font-size: 16px;
    }
    
    @include media_1000 {
        font-size: 15px;
    }
    
    @include media_700 {
        font-size: 14px;
    }
}

@mixin p3 {
    font-size: 20px;
    line-height: 1.7;
    
    @include media_1700 {
        font-size: 18px;
    }
    
    @include media_1300 {
        font-size: 16px;
    }
    
    @include media_1000 {
        font-size: 14px;
    }
}

.p3 {
    @include p3;
}

.p2 {
    font-size: 24px;
    line-height: 1;
    
    @include media_1700 {
        font-size: 20px;
    }
    
    @include media_1300 {
        font-size: 18px;
    }
    
    @include media_1000 {
        font-size: 16px;
    }
    
    @include media_700 {
    
    }
}

.p4 {
    font-size: 18px;
    line-height: 1;
    
    @include media_1700 {
        font-size: 17px;
    }
    
    @include media_1300 {
        font-size: 16px;
    }
    
    @include media_1000 {
        font-size: 15px;
    }
    
    @include media_700 {
        font-size: 14px;
    
    }
}

.p5 {
    font-size: 16px;
    line-height: 1.25;
    
    @include media_1300 {
        font-size: 14px;
    }
}


@mixin p6 {
    font-family: $metropolis;
    font-weight: 400;
    font-size: 16px;
    line-height: 2;
    
    @include media_900 {
        font-size: 15px;
    }
    
    @include media_700 {
        font-size: 13px;
    }
}

.p6 {
    @include p6;
}

.p7 {
    font-family: $metropolis;
    font-weight: 400;
    font-size: 20px;
    line-height: 1.7;
    
    
    @include media_1500 {
        font-size: 18px;
    }
    
    @include media_900 {
        font-size: 17px;
    }
    
    @include media_700 {
        font-size: 15px;
    }
}


.p8 {
    font-family: $metropolis;
    font-weight: 400;
    font-size: 14px;
    line-height: 1;
    
    @include media_700 {
        font-size: 12px;
    }
}

.b1 {
    font-weight: bold;
    font-size: 14px;
    line-height: 1;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    
    @include media_700 {
        font-size: 12px;
    }
}

.b2 {
    font-weight: 700;
    font-size: 15px;
    line-height: 1;
    
    @include media_700 {
        font-size: 14px;
    }
}

.link-style {
    color: $green;
    transition: $default-transition;
    @extend .b2;

    &:hover {
        color: $dark;
    }
}

.marker-list {
    @extend .p4;
    
    li {
        position: relative;
        @extend .mb40;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        
        &:last-child {
            margin-bottom: 0;
        }
        
        &::before {
            content: '';
            position: relative;
            display: block;
            margin-right: 20px;
            @extend .check-svg;
            width: 40px;
            height: 40px;
            flex: 0 0 auto;
        }
    }
    
    @include media_1700 {
        li {
            &::before {
                margin-right: 15px;
                width: 35px;
                height: 35px;
            }
        }
    }
    
    @include media_1300 {
        li {
            &::before {
                margin-right: 10px;
                width: 30px;
                height: 30px;
            }
        }
    }
    
    @include media_1000 {
        li {
            &::before {
                width: 25px;
                height: 25px;
            }
        }
    }
    
    @include media_700 {
        li {
            &::before {
                width: 20px;
                height: 20px;
            }
        }
    }
}

textarea {
    resize: none;
}

b,
strong,
.bold {
    font-weight: bold !important;
}

.article-header {
    text-align: center;
    
    &__date {
        display: block;
    }
}