$b1700: "all and (max-width: 1700px)";
$b1500: "all and (max-width: 1500px)";
$b1300: "all and (max-width: 1300px)";
$b1300-900: "all and (max-width: 1300px) and (min-width: 901px)";
$b1200: "all and (max-width: 1200px)";
$b1200-700: "all and (max-width: 1200px) and (min-width: 701px)";
$b1000: "all and (max-width: 1000px)";
$b900: "all and (max-width: 900px)";
$b700: "all and (max-width: 700px)";
$b600: "all and (max-width: 600px)";
$b500: "all and (max-width: 500px)";
$b350: "all and (max-width: 350px)";

$b2000_min: "all and (min-width: 2001px)";
$b1700_min: "all and (min-width: 1701px)";
$b1500_min: "all and (min-width: 1501px)";
$b1300_min: "all and (min-width: 1301px)";
$b1200_min: "all and (min-width: 1201px)";
$b1000_min: "all and (min-width: 1001px)";
$b900_min: "all and (min-width: 901px)";
$b700_min: "all and (min-width: 701px)";
$b500_min: "all and (min-width: 501px)";

@mixin media_1700 {
	@media #{$b1700} {
		@content;
	}
}
@mixin media_1500 {
	@media #{$b1500} {
		@content;
	}
}
@mixin media_1300 {
	@media #{$b1300} {
		@content;
	}
}
@mixin media_1300-900 {
	@media #{$b1300-900} {
		@content;
	}
}
@mixin media_1000 {
	@media #{$b1000} {
		@content;
	}
}
@mixin media_1200 {
	@media #{$b1200} {
		@content;
	}
}
@mixin media_1200-700 {
	@media #{$b1200-700} {
		@content;
	}
}
@mixin media_900 {
	@media #{$b900} {
		@content;
	}
}
@mixin media_700 {
	@media #{$b700} {
		@content;
	}
}
@mixin media_600 {
	@media #{$b600} {
		@content;
	}
}
@mixin media_500 {
	@media #{$b500} {
		@content;
	}
}
@mixin media_350 {
	@media #{$b350} {
		@content;
	}
}

@mixin media_min-2000 {
	@media #{$b2000_min} {
		@content;
	}
}
@mixin media_min-1700 {
	@media #{$b1700_min} {
		@content;
	}
}
@mixin media_min-1500 {
	@media #{$b1500_min} {
		@content;
	}
}
@mixin media_min-1300 {
	@media #{$b1300_min} {
		@content;
	}
}
@mixin media_min-1000 {
	@media #{$b1000_min} {
		@content;
	}
}
@mixin media_min-1200 {
	@media #{$b1200_min} {
		@content;
	}
}
@mixin media_min-900 {
	@media #{$b900_min} {
		@content;
	}
}
@mixin media_min-700 {
	@media #{$b700_min} {
		@content;
	}
}
@mixin media_min-500 {
	@media #{$b500_min} {
		@content;
	}
}

.show-on-1700 {
	@include media_min-1700 {
		display: none !important;
	}
}

.hide-on-1700 {
	@include media_1700 {
		display: none !important;
	}
}

.show-on-1500 {
	@include media_min-1500 {
		display: none !important;
	}
}

.hide-on-1500 {
	@include media_1500 {
		display: none !important;
	}
}

.show-on-1300 {
	@include media_min-1300 {
		display: none !important;
	}
}

.hide-on-1300 {
	@include media_1300 {
		display: none !important;
	}
}

.show-on-1200 {
	@include media_min-1200 {
		display: none !important;
	}
}

.hide-on-1200 {
	@include media_1200 {
		display: none !important;
	}
}

.show-on-1000 {
	@include media_min-1000 {
		display: none !important;
	}
}

.hide-on-1000 {
	@include media_900 {
		display: none !important;
	}
}

.show-on-700 {
	@include media_min-700 {
		display: none !important;
	}
}

.hide-on-700 {
	@include media_700 {
		display: none !important;
	}
}

.show-on-500 {
	@include media_min-500 {
		display: none !important;
	}
}

.hide-on-500 {
	@include media_500 {
		display: none !important;
	}
}


@mixin rh($multiplier: 1) {
	&:after {
		content: '';
		display: block;
		padding-top: #{100% * $multiplier};
	}
}

@mixin slider-preload($root, $item-name: 'item') {
	&:not(.slick-initialized) {
		opacity: 0;
		
		#{$root}__#{$item-name}:not(:first-child) {
			display: none;
		}
	}
}

@mixin absolute($top: 0, $right: 0, $bottom: 0, $left: 0) {
	position: absolute;
	top: $top;
	right: $right;
	bottom: $bottom;
	left: $left;
	margin: auto;
}

@mixin absolute-cm($top: 0, $right: 0, $bottom: 0, $left: 0) {
	position: absolute;
	top: $top;
	right: $right;
	bottom: $bottom;
	left: $left;
}

@mixin fixed($top: 0, $right: 0, $bottom: 0, $left: 0) {
	position: fixed;
	top: $top;
	right: $right;
	bottom: $bottom;
	left: $left;
}

@mixin of($fit: cover, $position: center) {
	@include absolute();
	@include object-fit($fit, $position);
	width: 100%;
	height: 100%;
}

.abs-fit-svg {
	@include absolute(0,auto,auto);
	display: block;
	width: 100%;
	height: auto;
}

.rel-contain-svg {
	position: relative;
	display: block;
	max-width: 100%;
	max-height: 100%;
	margin-left: auto;
	margin-right: auto;
}

.of {
	@include of();
}

@mixin shadow-substrate($shadow: $box-shadow-2, $radius: 10px) {
	&::before {
		content: '';
		display: block;
		@include absolute();
		width: 100%;
		height: 100%;
		z-index: -1;
		border-radius: $radius;
		box-shadow: $shadow;
	}
}