.social-list {
	$r: &;
	display: flex;
	justify-content: flex-start;
	align-items: flex-start;
	flex-wrap: wrap;
	margin-top: -30px;

	&__item {
		border-radius: 50%;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		width: 60px;
		height: 60px;
		margin-right: 30px;
		margin-top: 30px;
		transition: $default-transition;
		overflow: hidden;
		background: $dark;
		
		&:last-child {
			margin-right: 0;
		}
		
		svg {
			position: relative;
			display: block;
			max-width: 100%;
			max-height: 100%;
			fill: $white;
			
			g {
				fill: inherit !important;
				clip-path: none !important;
			}
			
			path {
				fill: inherit !important;
				transition: $default-transition;
			}
		}
		
		&:hover {
			background: $white;
			
			svg {
				fill: $dark;
			}
		}
	}
	
	
	
	@include media_1700 {
		margin-top: -15px;
		
		&__item {
			width: 50px;
			height: 50px;
			margin-right: 15px;
			margin-top: 15px;
			padding: 10px;
			box-sizing: border-box;
		}
	}
	
	@include media_1300 {
		margin-top: -10px;
		
		&__item {
			width: 40px;
			height: 40px;
			margin-right: 10px;
			margin-top: 10px;
		}
	}
	
	@include media_1000 {
	
	}
	
	@include media_700 {
	
	}
}
