.slick-slider {
    .slick-dots {
        position: relative;
        padding-bottom: 0;
        margin-top: 25px;

        li {
            button {
                padding: 5px;
                margin: 0 3px;

                &:before {
                    width: 7px;
                    height: 7px;
                    margin: auto;
                    background: transparent;
                    border: 1px solid $green;
                    box-sizing: border-box;
                }
            }

            &.slick-active {
                button {
                    &:before {
                        background: $green;
                    }
                }
            }
        }
    }
    
    
    @include media_700 {
        .slick-dots {
            margin-top: 15px;
        }
    }
}
