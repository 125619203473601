.intro {
	&__bg {
		width: 100%;
		height: calc(100% - 13.25vw);
		@include absolute(0,auto,auto);
		background: $gradient1;
		z-index: 0;
	}
	
	&__content {
		text-align: center;
	}
	
	&__paperplane {
		@include absolute-cm(-6px,auto,auto,calc(100% + 40px));
		@include rh(174/143);
		width: 143px;
		
		svg {
			@extend .abs-fit-svg;
		}
	}
	
	&__img-wrap {
		position: relative;
		width: 100%;
		max-width: 1525px;
		margin-left: auto;
		margin-right: auto;
		@include rh(464/1526);
		
		svg {
			@extend .abs-fit-svg;
		}
	}

	@include media_1700 {
		&__paperplane {
			top: -10px;
			width: 120px;
		}
	}

	@include media_1300 {
		&__paperplane {
			width: 100px;
			left: 100%;
		}
	}
	
	
	@include media_1000 {
		&__img-wrap {
			width: 120%;
			left: 50%;
			transform: translateX(-50%);
		}
		
		&__bg {
			height: calc(100% - 16vw);
		}
		
		&__paperplane {
			width: 80px;
			left: 90%;
		}
	}
	
	@include media_700 {
		&__img-wrap {
			width: 140%;
		}
		
		&__bg {
			height: calc(100% - 19vw);
		}
		
		&__paperplane {
			width: 70px;
			top: 0;
		}
	}
	
	@include media_500 {
		&__img-wrap {
			width: 175%;
		}
		&__bg {
			height: calc(100% - 25vw);
		}
		&__paperplane {
			width: 50px;
		}
	}
}

@keyframes intro-pen-wrap {
	0% {
		opacity: 0;
		transform: translate3d(0, 0, 0);
	}
	
	10% {
		opacity: 1;
		transform: translate3d(0, 0, 0);
	}
	
	30% {
		opacity: 1;
		transform: translate3d(0, 0, 0);
	}
	
	90% {
		opacity: 1;
		transform: translate3d(73%, 16%, 0);
	}
	
	100% {
		opacity: 0;
		transform: translate3d(73%, 16%, 0);
	}
}

@keyframes intro-pen {
	0%, 30%, 40%, 50%, 60%, 70%, 80%, 90% {
		transform: rotate(0deg);
	}
	
	35%, 45%, 55% , 65% , 75% , 85% {
		transform: rotate(-3deg);
	}
}

.intro-pen-wrap {
	transform-box: fill-box;
	animation: intro-pen-wrap 3s linear infinite;
}

.intro-pen {
	transform-box: fill-box;
	animation: intro-pen 3s linear infinite;
	transform-origin: 100% 100%;
}

@keyframes intro-dollar {
	0% {
		transform: translate3d(0,0,0);
	}
	
	50% {
		transform: translate3d(0,-7%,0);
	}
	
	100% {
		transform: translate3d(0,0,0);
	}
}

.intro-dollar {
	transform-box: fill-box;
	animation: intro-dollar 2s ease-in-out infinite;
}

@keyframes intro-man-hand {
	0% {
		transform: rotate(0deg);
	}
	
	30% {
		transform: rotate(0deg);
	}
	
	55% {
		transform: rotate(-5deg);
	}
	
	80% {
		transform: rotate(4deg);
	}
	
	100% {
		transform: rotate(0deg);
	}
}

.intro-man-hand {
	transform-box: fill-box;
	transform-origin: 90% 10%;
	animation: intro-man-hand 3s ease-in-out infinite;
}

@keyframes intro-check {
	0% {
		opacity: 0;
		transform: translate(-42%, 62%);
	}
	
	1% {
		opacity: 1;
	}
	
	40% {
		opacity: 1;
		transform: translate(0, 0);
	}
	
	80% {
		opacity: 1;
		transform: translate(0, 0);
	}
	
	100% {
		opacity: 0;
		transform: translate(5%, -5%) rotate(30deg);
	}
}

.intro-check {
	transform-box: fill-box;
	transform: translate(-42%, 62%);
	animation: intro-check 7s ease-in-out infinite;
	transform-origin: 60% 100%;
}

@keyframes intro-girl {
	0% {
		transform: translate3d(0, 0, 0);
	}
	
	50% {
		transform: translate3d(0, 0, 0);
	}
	
	75% {
		transform: translate3d(3%, 0, 0);
	}
	
	100% {
		transform: translate3d(0, 0, 0);
	}
}

@keyframes intro-girl-limb {
	0% {
		transform: rotate(0deg);
	}
	
	50% {
		transform: rotate(0deg);
	}
	
	75% {
		transform: rotate(5deg);
	}
	
	100% {
		transform: rotate(0deg);
	}
}

$girlAnimationDuration: 5s;

.intro-girl {
	transform-box: fill-box;
	animation: intro-girl $girlAnimationDuration ease-in-out infinite;
}

.intro-girl-left-leg {
	transform-box: fill-box;
	transform-origin: 60% 60%;
	animation: intro-girl-limb $girlAnimationDuration ease-in-out infinite;
	animation-delay: $girlAnimationDuration/4 * 1.5;
}

.intro-girl-right-leg {
	transform-box: fill-box;
	transform-origin: 25% 5%;
	animation: intro-girl-limb $girlAnimationDuration ease-in-out infinite;
	animation-delay: 0s;
}

.intro-girl-left-hand {
	transform-box: fill-box;
	transform-origin: 5% 5%;
	animation: intro-girl-limb $girlAnimationDuration ease-in-out infinite;
	animation-delay: $girlAnimationDuration/4 * 2.5;
}

.intro-girl-right-hand {
	transform-box: fill-box;
	transform-origin: 5% 5%;
	animation: intro-girl-limb $girlAnimationDuration ease-in-out infinite;
	animation-delay: $girlAnimationDuration/4 * 3;
}

@keyframes intro-man-2-hand {
	0% {
		transform: rotate(0deg);
	}
	
	50% {
		transform: rotate(0deg);
	}
	
	75% {
		transform: rotate(-20deg);
	}
	
	100% {
		transform: rotate(0deg);
	}
}

.intro-man-2-hand {
	transform-box: fill-box;
	transform-origin: 50% 75%;
	animation: intro-man-2-hand 4s ease-in-out infinite;
}


@keyframes intro-man-2-head {
	0% {
		transform: rotate(0deg);
	}
	
	50% {
		transform: rotate(0deg);
	}
	
	62.5% {
		transform: rotate(6deg);
	}
	
	75% {
		transform: rotate(0deg);
	}
	
	87.5% {
		transform: rotate(6deg);
	}
	
	100% {
		transform: rotate(0deg);
	}
}

.intro-man-2-head {
	transform-box: fill-box;
	transform-origin: 70% 85%;
	animation: intro-man-2-head 4s ease-in-out infinite;
	animation-delay: 2s;
}

@keyframes paperplane {
	0% {
		transform: translate3d(0,0,0);
	}
	
	50% {
		transform: translate3d(5%,-5%,0);
	}
	
	100% {
		transform: translate3d(0,0,0);
	}
}

.paperplane-main {
	animation: paperplane 4s ease-in-out infinite;
}

.paperplane-wind {
	animation: paperplane 4s ease-in-out infinite;
	animation-delay: .2s;
}