@mixin select-module-styles {
    @include input-wrap;
    position: relative;
    perspective: 1000px;

    &__select {
        @include error-styles;
    }

    &__placeholder {
    
    }

    &__text-block {
        @extend .p3;
        line-height: 1;
        max-width: 100%;
        overflow: hidden;
    }
    
    
    &__label {
        color: $white;
    }

    &__input-container {
        @include input-style;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        cursor: pointer;
        padding-right: 50px;
    
        &::after {
            content: '';
            @include absolute(0,25px,0,auto);
            width: 12px;
            height: 9px;
            display: block;
            @extend .arrow-down-white;
            transition: $default-transition;
            transform-origin: 50% 50%;
        }
    }

    &__dropdown {
        z-index: 10;
        position: absolute;
        left: 0;
        top: calc(100% + 10px);
        width: 100%;
        overflow: hidden;
        background: $white;
        pointer-events: none;
        transform: rotateX(90deg) translateY(0);
        transform-origin: 0 0;
        transition: transform 1s ease, opacity 0.5s ease;
        opacity: 0;
        border-radius: 5px;
        @extend .p5;
        box-shadow: 0 10px 20px rgba(52, 146, 126, 0.34);
    }

    &__scroll {
        position: relative;
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        flex-direction: column;
        max-height: 300px;
        padding: 10px;
    }

    &__option {
        position: relative;
        display: block;
        width: 100%;
        cursor: pointer;
        padding: 10px 0;
        color: black;
        transition: $default-transition;

        &:hover,
        &._active {
            color: $green;
        }
    }

    &._active {
        z-index: 222;
    }

    &._active & {
        &__input-container {
            &::after {
                transform: scaleY(-1);
            }
        }

        &__dropdown {
            transform: rotateX(0deg);
            opacity: 1;
            pointer-events: all;
        }
    }
}

.select-module {
    @include select-module-styles;
    display: flex;
    flex-direction: column-reverse;
    align-items: flex-start;
    justify-content: flex-start;
    
    p {
        display: block;
        
        span {
            display: block;
        }
    }
}
