.content-section {
	@extend .mb100;
	
	&:last-child {
		margin-bottom: 0;
	}
}

.inner-container {
	.intro-text,
	.text-block {
		width: calc(100% / 10 * 8);
		margin-left: auto;
		margin-right: auto;
	}
	
	@include media_1300 {
		.intro-text,
		.text-block {
			width: 100%;
		}
	}
}

.intro-text {
	@extend .mb60;
	
	p {
		@extend .p7;
		@extend .mb40;
		
		&:last-child {
			margin-bottom: 0;
		}
	}
}

.text-block {
	@extend .mb100;
	
	&:last-child {
		margin-bottom: 0;
	}
	
	p {
		@extend .p6;
		@extend .mb40;
		
		&:last-child {
			margin-bottom: 0;
		}
	}
	
	q, blockquote {
		position: relative;
		display: block;
		@extend .pt50;
		@extend .pb50;
		@extend .mt60;
		@extend .mb60;
		@extend .p7;
		padding-left: calc(100% / 8);
		
		&::before {
			content: '\e910';
			font-family: $icomoon;
			font-size: 60px;
			line-height: 1;
			font-weight: 100;
			color: $green;
			display: block;
			@include absolute-cm(0,auto,auto);
			@extend .mt50;
		}
		
		&::after {
			content: '';
			display: block;
			@include absolute(0,auto,auto,50%);
			transform: translateX(-50%);
			width: calc(100% / 8 * 10);
			height: 100%;
			border-radius: 10px;
			background: $gray5;
			z-index: -1;
		}
	}
	
	h1, h2, h3, h4, h5, h6 {
		@extend .mt40;
		
		&:first-child {
			margin-top: 0;
		}
	}
	
	h1 {
		@extend .h1;
		@extend .mb60;
	}
	
	h2 {
		@extend .h2;
		@extend .mb60;
	}
	
	h3 {
		@extend .h3;
		@extend .mb40;
	}
	
	ul {
		@extend .p6;
		@extend .mb20;
		@extend .mt20;
		
		li {
			display: flex;
			align-items: baseline;
			justify-content: flex-start;
			
			&::before {
				content: '';
				top: -0.1em;
				display: block;
				position: relative;
				height: 6px;
				width: 6px;
				background: $green;
				border-radius: 50%;
				margin-right: 15px;
				flex: 0 0 auto;
			}
		}
	}
	
	ol {
		@extend .p6;
		@extend .mb20;
		@extend .mt20;
		counter-reset: list;
		
		li {
			display: flex;
			align-items: baseline;
			justify-content: flex-start;
			
			&::before {
				counter-increment: list;
				content: counter(list, decimal-leading-zero) '.';
				margin-right: 10px;
				display: block;
				font-weight: 800;
				flex: 0 0 auto;
			}
		}
	}
	
	
	@include media_1500 {
		
		q, blockquote {
			padding-left: 86px;
		}
	}
	
	@include media_1300 {
		q, blockquote {
			&::after {
				width: calc(100% / 10 * 12);
			}
		}
		
		ul {
			li {
				&::before {
					margin-right: 10px;
				}
			}
		}
	}
	
	@include media_1000 {
		q, blockquote {
			&::after {
				width: calc(100% / 12 * 14);
			}
		}
	}
	
	@include media_700 {
		q, blockquote {
			display: flex;
			flex-direction: column;
			align-items: flex-start;
			justify-content: flex-start;
			padding-left: 20px;
			padding-right: 20px;
			
			&::before {
				position: relative;
				display: block;
				font-size: 34px;
				margin-top: 0;
				margin-bottom: 20px;
			}
			
			&::after {
				width: 100%;
			}
		}
	}
}