.advantages {

}

.advantages-list {
	position: relative;
	width: calc(100% + 20px);
	left: -10px;
	display: flex;
	align-items: stretch;
	justify-content: flex-start;
	flex-wrap: wrap;
	margin-top: -20px;
	
	&__item {
		position: relative;
		width: calc(25% - 20px);
		margin: 20px 10px 0;
		text-align: center;
		padding: 0 20px 50px;
		
		&::before {
			content: '';
			@include absolute(auto);
			width: 100%;
			height: calc(100% - 50px);
			background: $gray4;
			z-index: -1;
		}
	}
	
	&__img-wrap {
		width: 180px;
		height: 180px;
		margin-left: auto;
		margin-right: auto;
		
		svg {
			@extend .rel-contain-svg;
		}
	}
	
	&__text {
		text-align: center;
	}
	
	@include media_1700 {
		&__img-wrap {
			width: 150px;
			height: 150px;
		}
	}
	
	@include media_1300 {
		&__img-wrap {
			width: 120px;
			height: 120px;
		}
		
		&__item {
			padding: 0 10px 30px;
		}
	}
	
	@include media_1000 {
		&__img-wrap {
			width: 100px;
			height: 100px;
		}
		
		&__item {
			width: calc(50% - 20px);
			padding: 0 20px 30px;
		}
	}
	
	@include media_700 {
	
	}
	
	@include media_500 {
		&__img-wrap {
			width: 90px;
			height: 90px;
		}
		
		&__item {
			width: calc(100% - 20px);
			padding: 0 20px 30px;
		}
	}
}

@keyframes advantage-opacity {
	0% {
		opacity: 1;
	}
	
	50% {
		opacity: 1;
	}
	
	75% {
		opacity: 0;
	}
	
	100% {
		opacity: 1;
	}
}

@keyframes advantage-opacity2 {
	0% {
		opacity: 1;
	}
	
	50% {
		opacity: 1;
	}
	
	75% {
		opacity: 0.5;
	}
	
	100% {
		opacity: 1;
	}
}

.advantage-1-graphic {
	animation: advantage-opacity2 4s ease-in-out infinite;
}

.advantage-1-graphic-2 {
	animation-delay: 2s
}

$serviceAnimationDuration: 5s;

.advantage-2-day {
	animation: advantage-opacity 5s ease-in-out infinite;
}

.advantage-2-day-2 {
	animation-delay:$serviceAnimationDuration / 7 * 1;
}

.advantage-2-day-3 {
	animation-delay:$serviceAnimationDuration / 7 * 2;
}

.advantage-2-day-4 {
	animation-delay:$serviceAnimationDuration / 7 * 3;
}

.advantage-2-day-5 {
	animation-delay:$serviceAnimationDuration / 7 * 4;
}

.advantage-2-day-6 {
	animation-delay:$serviceAnimationDuration / 7 * 5;
}

.advantage-2-day-7 {
	animation-delay:$serviceAnimationDuration / 7 * 6;
}

@keyframes advantage-3-gear {
	0% {
		transform: translate3d(0,0,0);
	}
	
	50% {
		transform: translate3d(0,0,0);
	}
	
	75% {
		transform: translate3d(0,-2%,0);
	}
	
	100% {
		transform: translate3d(0,0,0);
	}
}

.advantage-3-gear {
	animation: advantage-3-gear 3s ease-in-out infinite;
}

.advantage-3-gear-small {
	animation-delay: .3s;
}

@keyframes advantage-3-arrow {
	0% {
		transform: translate3d(0,0,0);
		opacity: 1;
	}
	50% {
		transform: translate3d(0,0,0);
		opacity: 1;
	}
	
	60% {
		transform: translate3d(0,0,0);
		opacity: 0;
	}
	
	65% {
		transform: translate3d(-7%, -10%, 0);
		opacity: 0;
	}
	
	75% {
		transform: translate3d(-7%, -10%, 0);
		opacity: 1;
	}
	
	95% {
		transform: translate3d(0,0,0);
		opacity: 1;
	}
	
	100% {
		transform: translate3d(0,0,0);
		opacity: 1;
	}
}

.advantage-3-arrow {
	animation: advantage-3-arrow 3s ease-in-out infinite;
}

@keyframes advantage-4-shield {
	0% {
		transform: scale(1);
	}
	
	60% {
		transform: scale(1);
	}
	
	80% {
		transform: scale(1.035);
	}
	
	100% {
		transform: scale(1);
	}
}

.advantage-4-shield {
	transform-box: fill-box;
	transform-origin: 50% 50%;
	animation: advantage-4-shield 3s ease-in-out infinite;
}
