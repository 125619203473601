.logo {
	display: flex;
	align-items: stretch;
	justify-content: flex-start;
	
	&__img-wrap {
		display: block;
		width: 200px;
		height: auto;
	}
	
	&__img {
		display: block;
		width: 100%;
		height: auto;
	}
	
	@include media_1700 {
		&__img-wrap {
			width: 170px;
		}
	}
	
	@include media_1300 {
		&__img-wrap {
			width: 150px;
		}
	}
	
	@include media_1000 {
		&__img-wrap {
			width: 130px;
		}
	}
	
	@include media_700 {
		&__img-wrap {
			width: 100px;
		}
	}
}
