.overlay {
	@include fixed(0,auto,auto,-200vw);
	height: 100vh;
	width: 100vw;
	margin: auto;
	z-index: 950;
	background: rgba(0,0,0,0.8);
	opacity: 0;
	transition-property: opacity, left;
	transition-timing-function: ease-in-out, linear;
	transition-duration: .3s, 0s;
	transition-delay: 0s, .3s;
	
	&._active {
		transition-duration: .5s, 0s;
		transition-delay: 0s, 0s;
		opacity: 1;
		left: 0;
	}
}

.popup-style {
	@include fixed(0,auto,auto,-200vw);
	background-color: transparent;
	box-sizing: border-box;
	height: 100vh;
	opacity: 0;
	transition: 0s ease;
	width: 100%;
	z-index: 960;
	transition-delay: 1s;
	pointer-events: none;
	padding: 10px;
	
	&__container {
		height: 100%;
		width: 100%;
		display: flex;
		justify-content: flex-start;
		pointer-events: none;
		align-items: center;
		flex-direction: column;
	}
	
	&._active {
		left: 0;
		opacity: 1;
		transition-delay: 0s;
	}
	
	&__wrap {
		position: relative;
		box-sizing: border-box;
		max-height: 100%;
		padding: 80px 0 20px;
		width: calc(100% / 12 * 8);
		margin: auto;
		flex: 0 0 auto;
		opacity: 0;
		transform: scale(.95);
		transition: transform .3s ease-in-out, opacity .3s ease-in-out;
		transition-delay: 0s;
		pointer-events: auto;
		background: linear-gradient(90deg, #63C58C 0%, #41BCA8 100%);
		border-radius: 10px;
		
		& > .cross-btn {
			@include absolute(0,auto,auto,calc(100% + 20px));
			z-index: 50;
		}
	}
	
	&__scroll-wrap {
		position: relative;
		height: auto;
		max-height: calc(100vh - 100px);
		width: 100%;
		overflow: hidden;
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		justify-content: flex-start;
		z-index: 10;
		
		.mCustomScrollBox {
			width: 100%;
		}
	}
	
	&__content {
		position: relative;
		padding-left: 20px;
		box-sizing: border-box;
		width: calc(100% / 8 * 6);
		height: auto;
		margin: auto;
		padding-bottom: 60px;
	}
	
	&._active & {
		&__wrap {
			transition: transform 1s ease-in-out, opacity 1s ease-in-out;
			opacity: 1;
			transform: scale(1);
			transition-delay: .75s;
		}
		
		&__cloud {
			opacity: 1;
			transition: 1s ease-in-out;
			
			@for $i from 1 through 3 {
				&:nth-child(#{$i}) {
					transition-delay: #{(500 + (250 * ($i - 1)) + ms)};
				}
			}
		}
		
		&__icon {
			animation: plane_animation 2s linear infinite;
		}
	}
	
	@include media_1700 {
		&__wrap {
			padding: 60px 0 20px;
			width: calc(100% / 12 * 8);
		}
		
		&__leaves {
			bottom: 0;
			left: 82.9%;
		}
		
		&__scroll-wrap {
			max-height: calc(100vh - 80px);
		}
	}
	
	@include media_1500 {
		&__wrap {
			width: calc(100% / 12 * 10);
		}
	}
	
	@include media_1300 {
		&__wrap {
			width: calc(100% / 12 * 11);
		}
	}
	
	@include media_900 {
		&__container {
			padding: 0 80px;
		}
		
		&__wrap {
			width: 100%;
		}
	}
	
	@include media_700 {
		&__wrap {
			padding: 40px 0 20px;
		}
		
		&__leaves {
			left: 84.8%;
		}
		&__scroll-wrap {
			max-height: calc(100vh - 60px);
		}
	}
	
	@include media_500 {
		&__container {
			padding: 0;
		}
		
		&__content {
			width: 100%;
		}
		
		&__wrap {
			width: 100%;
			padding: 80px 0 40px;
			
			& > .cross-btn {
				top: 20px;
				right: 20px;
				bottom: auto;
				left: auto;
				background: $white;
				
				&::before,
				&::after {
					background: $green;
				}
			}
		}
		
		&__scroll-wrap {
			max-height: calc(100vh - 120px);
		}
	}
}

.ty-content {
	position: relative;
	text-align: center;
	margin: 0 auto;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: flex-start;
	
	&__title {
		display: block;
		color: $white;
	}
	
	&__subtitle {
		display: block;
	}
}
