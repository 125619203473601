.search-results {

}

.search-results-list {
	&__item {
		&:last-child {
			margin-bottom: 0;
		}
	}
}

.result-link {
	position: relative;
	display: flex;
	align-items: flex-start;
	justify-content: flex-start;
	background: $white;
	border-radius: 10px;
	padding: 30px 50px;
	@include shadow-substrate;
	
	& * {
		position: relative;
		display: block;
	}
	
	&__img-wrap {
		width: 90px;
		height: 90px;
		margin-right: 50px;
		border-radius: 10px;
		overflow: hidden;
		flex: 0 0 auto;
	}
	
	&__title {
		color: $dark;
		transition: $default-transition;
	}
	
	&__text {
		color: $dark;
		margin-top: 10px;
	}
	
	&:hover &__title {
		color: $green;
	}
	
	@include media_900 {
		padding: 30px;
		
		&__img-wrap {
			margin-right: 30px;
		}
	}
	
	@include media_700 {
		padding: 10px;
		
		&__img-wrap {
			width: 60px;
			height: 60px;
			margin-right: 20px;
		}
		
		&__text {
			margin-top: 5px;
		}
		
	}
}

.search-no-results {
	&__content {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: flex-start;
	}
	
	&__svg-wrap {
		position: relative;
		width: 396px;
		max-width: 100%;

		svg {
			display: block;
			position: relative;
			width: 100%;
			height: auto;
			
			@keyframes move-snr-hand {
				0% {
					transform: rotate(16deg);
				}
				
				40% {
					transform: rotate(30deg);
				}
				
				80% {
					transform: rotate(16deg);
				}
			}
			
			@keyframes move-magnifier {
				0% {
					transform: rotate(-10deg);
				}
				
				40% {
					transform: rotate(0deg);
				}
				
				80% {
					transform: rotate(-10deg);
				}
			}
			
			.left-hand-top,
			.left-hand-bottom {
				transform-origin: 82.8% 45.3%;
				transform: rotate(16deg);
				animation: move-snr-hand 10s ease-in-out infinite;
			}
			
			.right-hand-top,
			.right-hand-bottom {
				transform-origin: 91.5% 47.3%;
			}
			
			.magnifier {
				transform-origin: 97.4% 67.6%;
				transform: rotate(-10deg);
				animation: move-magnifier 10s ease-in-out infinite;
			}
		}
	}
	
	&__description {
		text-align: center;
	}
	
	
	@include media_1500 {
		&__svg-wrap {
			width: 287px;
		}
	}
	
	@include media_1300 {
		&__svg-wrap {
			width: 185px;
		}
	}
	
	@include media_900 {
	}
	
	@include media_700 {
	}
}
