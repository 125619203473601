$timingFunction: ease;
$baseDuration: 0.3;

$default-transition: all #{($baseDuration * 1) + s} $timingFunction;
$default-transition-2: all #{($baseDuration * 2) + s} $timingFunction;
$default-transition-3: all #{($baseDuration * 3) + s} $timingFunction;
$default-transition-4: all #{($baseDuration * 4) + s} $timingFunction;
$default-transition-5: all #{($baseDuration * 5) + s} $timingFunction;

@keyframes cloud-animation {
    0%,
    50%,
    100% {
        transform: translateY(0px) translateX(0px);
    }
    
    30%,
    80% {
        transform: translateY(5px) translateX(5px);
    }
}

@keyframes floating {
    0% {
        transform: translateY(0px);
    }
    
    50% {
        transform: translateY(40px);
    }
    
    100% {
        transform: translateY(0);
    }
}

@keyframes rotating {
    0% {
        transform: rotate(0deg);
    }
    
    100% {
        transform: rotate(360deg);
    }
}

@keyframes animate-leaves {
    0% {
        transform: skewX(-2deg);
    }
    
    100% {
        transform: skewX(2deg);
    }
}

.svg-animation-wrap {
    .animate-in-window {
        animation-play-state: paused;
    }
    
    &._in-window {
        .animate-in-window {
            animation-play-state: running;
        }
    }
}

$coinAnimationTranslateY: -200%;

@keyframes coin-animation-1 {
    0% {
        opacity: 1;
        transform: translate3d(0,0,0);
    }
    40% {
        opacity: 1;
        transform: translate3d(0,0,0);
    }
    43% {
        opacity: 0;
        transform: translate3d(0,0,0);
    }
    45% {
        opacity: 0;
        transform: translate3d(0,$coinAnimationTranslateY,0);
    }
    68% {
        opacity: 0;
        transform: translate3d(0,$coinAnimationTranslateY,0);
    }
    70% {
        opacity: 1;
        transform: translate3d(0,$coinAnimationTranslateY,0);
    }
    100% {
        opacity: 1;
        transform: translate3d(0,0,0);
    }
}

@keyframes coin-animation-2 {
    0% {
        opacity: 1;
        transform: translate3d(0,0,0);
    }
    42% {
        opacity: 1;
        transform: translate3d(0,0,0);
    }
    45% {
        opacity: 0;
        transform: translate3d(0,0,0);
    }
    47% {
        opacity: 0;
        transform: translate3d(0,$coinAnimationTranslateY,0);
    }
    66% {
        opacity: 0;
        transform: translate3d(0,$coinAnimationTranslateY,0);
    }
    68% {
        opacity: 1;
        transform: translate3d(0,$coinAnimationTranslateY,0);
    }
    95% {
        opacity: 1;
        transform: translate3d(0,0,0);
    }
}

@keyframes coin-animation-3 {
    0% {
        opacity: 1;
        transform: translate3d(0,0,0);
    }
    44% {
        opacity: 1;
        transform: translate3d(0,0,0);
    }
    47% {
        opacity: 0;
        transform: translate3d(0,0,0);
    }
    49% {
        opacity: 0;
        transform: translate3d(0,$coinAnimationTranslateY,0);
    }
    64% {
        opacity: 0;
        transform: translate3d(0,$coinAnimationTranslateY,0);
    }
    66% {
        opacity: 1;
        transform: translate3d(0,$coinAnimationTranslateY,0);
    }
    90% {
        opacity: 1;
        transform: translate3d(0,0,0);
    }
}

@keyframes coin-animation-4 {
    0% {
        opacity: 1;
        transform: translate3d(0,0,0);
    }
    46% {
        opacity: 1;
        transform: translate3d(0,0,0);
    }
    49% {
        opacity: 0;
        transform: translate3d(0,0,0);
    }
    51% {
        opacity: 0;
        transform: translate3d(0,$coinAnimationTranslateY,0);
    }
    62% {
        opacity: 0;
        transform: translate3d(0,$coinAnimationTranslateY,0);
    }
    64% {
        opacity: 1;
        transform: translate3d(0,$coinAnimationTranslateY,0);
    }
    85% {
        opacity: 1;
        transform: translate3d(0,0,0);
    }
}

@keyframes coin-animation-5 {
    0% {
        opacity: 1;
        transform: translate3d(0,0,0);
    }
    48% {
        opacity: 1;
        transform: translate3d(0,0,0);
    }
    51% {
        opacity: 0;
        transform: translate3d(0,0,0);
    }
    53% {
        opacity: 0;
        transform: translate3d(0,$coinAnimationTranslateY,0);
    }
    60% {
        opacity: 0;
        transform: translate3d(0,$coinAnimationTranslateY,0);
    }
    62% {
        opacity: 1;
        transform: translate3d(0,$coinAnimationTranslateY,0);
    }
    80% {
        opacity: 1;
        transform: translate3d(0,0,0);
    }
}

@keyframes coin-animation-6 {
    0% {
        opacity: 1;
        transform: translate3d(0,0,0);
    }
    50% {
        opacity: 1;
        transform: translate3d(0,0,0);
    }
    53% {
        opacity: 0;
        transform: translate3d(0,0,0);
    }
    55% {
        opacity: 0;
        transform: translate3d(0,$coinAnimationTranslateY,0);
    }
    58% {
        opacity: 0;
        transform: translate3d(0,$coinAnimationTranslateY,0);
    }
    60% {
        opacity: 1;
        transform: translate3d(0,$coinAnimationTranslateY,0);
    }
    75% {
        opacity: 1;
        transform: translate3d(0,0,0);
    }
}

.coins-animation {
    & > * {
        transform-box: fill-box;
        
        &:nth-child(1) {
            animation: coin-animation-6 5s ease-in-out infinite;
        }

        &:nth-child(2) {
            animation: coin-animation-5 5s ease-in-out infinite;
        }

        &:nth-child(3) {
            animation: coin-animation-4 5s ease-in-out infinite;
        }

        &:nth-child(4) {
            animation: coin-animation-3 5s ease-in-out infinite;
        }

        &:nth-child(5) {
            animation: coin-animation-2 5s ease-in-out infinite;
        }

        &:nth-child(6) {
            animation: coin-animation-1 5s ease-in-out infinite;
        }
    }
}

.coins-animation-d1 {
    & > * {
        animation-delay: (5s/3) !important;
    }
}

.coins-animation-d2 {
    & > * {
        animation-delay: (5s/3*2) !important;
    }
}

.svg-animation-wrap {
    .coins-animation {
        & > * {
            animation-play-state: paused;
        }
    }
    
    &._in-window {
        .coins-animation {
            & > * {
                animation-play-state: running;
            }
        }
    }
}