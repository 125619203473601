.object-fit-wrap {
	position: relative;
	display: block;
	height: 100%;
	width: 100%;
	
	picture {
		display: block;
		height: 100%;
		width: 100%;
	}
	
	&__img-wrapper {
		position: absolute;
		left: 0;
		top: 0;
		display: block;
		width: 100%;
		height: 100%;
	}
	
	&__img {
		width: 100%;
		height: 100%;
		margin: auto;
		@include object-fit(cover, center);
	}
	
	&_contain & {
		&__img {
			@include object-fit(contain, center);
		}
	}
}
