.illustrated-description {
	@extend .mb100;
	
	&:last-child {
		margin-bottom: 0;
	}
	
	&__media-wrap {
		position: relative;
		border-radius: 20px;
		overflow: hidden;
	}
	
	&__description {
		width: calc(100% / 12 * 10);
		margin-left: auto;
		margin-right: auto;
		display: flex;
		align-items: flex-start;
		justify-content: space-between;
	}
	
	&__col {
		&_left {
			width: calc(100% / 10 * 3);
		}
		
		&_right {
			width: calc(100% / 10 * 6);
		}
	}
	
	@include media_1300 {
		&__description {
			flex-direction: column;
			align-items: flex-start;
			justify-content: flex-start;
		}
		
		&__col {
			width: 100%;
			
			& + & {
				margin-top: 50px;
			}
		}
	}
	
	@include media_900 {
		&__col {
			& + & {
				margin-top: 40px;
			}
		}
	}
	
	@include media_700 {
		&__description {
			width: 100%;
		}
		
		&__col {
			& + & {
				margin-top: 30px;
			}
		}
	}
}