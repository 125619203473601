@keyframes nf-floating {
	0% {
		transform: translateY(0);
	}
	
	25% {
		transform: translateY(-5%);
	}
	
	50% {
		transform: translateY(0);
	}
	
	100% {
		transform: translateY(0);
	}
}

.not-found {
	&__container {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: flex-start;
	}
	
	&__svg-wrap {
		width: 735px;
		max-width: 100%;
		
		svg {
			display: block;
			position: relative;
			max-width: 100%;
			height: auto;
			overflow: visible;
			margin-left: auto;
			margin-right: auto;
		}
		
		.nf-item {
			$nfAnimationDuration: 3s;
			animation: nf-floating $nfAnimationDuration ease-in-out infinite;
			
			&.nf-item-2 {
				animation-delay: $nfAnimationDuration / 12;
			}
			
			&.nf-item-3 {
				animation-delay: $nfAnimationDuration / 12 * 2;
			}
		}
	}
	
	&__description {
		text-align: center;
	}
	
	@include media_1300 {
		&__svg-wrap {
			width: 474px;
		}
	}
	
	@include media_900 {
		&__svg-wrap {
			width: 370px;
		}
	}
}