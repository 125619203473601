.breadcrumbs {
    text-align: center;
    
    &__container {
        display: flex;
        justify-content: center;
        align-items: flex-start;
    }
    
    &__wrap {
        border-radius: 30px;
        padding: 5px 10px;
        background: $gray5;
    }

    a, span {
        display: inline;
        transition: $default-transition;
        position: relative;
    }
    
    span {
        color: $dark;
    }

    a {
        margin-right: .2em;
        color: $green;

        &::after {
            content: '/';
            display: inline-block;
            margin-left: .3em;
            pointer-events: none;
            color: $dark;
        }

        &:hover {
            color: $dark;
        }

    }
}
