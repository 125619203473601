.solutions {

}

.solutions-table {
	display: flex;
	align-items: stretch;
	justify-content: space-between;
	
	&__col {
		&_left {
			width: calc(100% / 10 * 5 - 10px);
		}
		
		&_right {
			width: calc(100% / 10 * 4 - 10px);
			padding-top: 65px;
		}
	}
	
	&__img-wrap {
		position: relative;
		width: 100%;
		@include rh(550/638);
		
		svg {
			@extend .abs-fit-svg;
		}
	}
	
	@include media_1700 {
		&__col {
			&_right {
				width: calc(100% / 10 * 4.5 - 10px);
				padding-top: 0;
			}
		}
	}
	
	@include media_1300 {
		width: 100%;
		
		&__col {
			&_left {
				width: calc(100% / 10 * 4 - 10px);
			}
			
			&_right {
				width: calc(100% / 10 * 5.5 - 10px);
			}
		}
	}
	
	@include media_1000 {
		flex-direction: column-reverse;
		align-items: center;
		justify-content: flex-start;
		
		
		&__col {
			width: 100%;
			
			&_left {
				max-width: 300px;
				margin-top: -110px;
				margin-left: calc(100% / 12 * 6);
				margin-right: auto;
				position: relative;
			}
		}
	}
	
	@include media_700 {
		&__col {
			&_left {
				max-width: 200px;
				margin-top: -20px;
				margin-left: calc(100% / 12 * 4);
			}
		}
	}
}

@keyframes solutions-check {
	0% {
		transform: scaleX(1);
	}
	
	70% {
		transform: scaleX(1);
	}
	
	85% {
		transform: scaleX(0.97);
	}
	
	100% {
		transform: scaleX(1);
	}
}

.solutions-check {
	transform-box: fill-box;
	transform-origin: 50% 50%;
	animation: solutions-check 3s ease-in-out infinite;
}

