.video {
	position: relative;
	overflow: hidden;
	width: 100%;
	height: 500px;
	
	&__overlay {
		@include absolute(0,0,auto);
		width: 100%;
		height: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
		z-index: 5;
		opacity: 1;
		transition-property: opacity, top;
		transition-duration: 1s, 0s;
		transition-delay: 0s, 1s;
		transition-timing-function: ease, linear;
	}


	&__mask {
		@include absolute;
		width: 100%;
		height: 100%;
		z-index: 3;
		transition: opacity .3s ease;
	}

	&__play-btn {
		display: block;
		position: relative;
		z-index: 5;
		cursor: pointer;
		padding: 20px;
		pointer-events: none;
		transition: $default-transition;

		&::before,
		&::after {
			color: $white;
			transition: $default-transition;
		}

		&::before {
			content: '\f04b';
			display: block;
			position: relative;
			opacity: 0;
			z-index: 1;
		}

		&::after {
			content: '\f1ce';
			@include absolute;
			width: 100%;
			height: 100%;
			display: flex;
			align-items: center;
			justify-content: center;
			z-index: 2;
			opacity: 1;
			animation: rotating 2s linear infinite;
		}

		&:hover {
			opacity: .5;
		}
	}

	&__play-btn:hover ~ & {
		&__img {
			transform: scale(1.05);
		}
	}

	&._play & {
		&__overlay {
			top: -110%;
			opacity: 0;
		}
	}

	&._ready & {
		&__play-btn {
			pointer-events: all;

			&::before {
				opacity: 1;
			}

			&::after {
				opacity: 0;
			}
		}
	}
	
	@include media_1500 {
		height: 361px;
	}
	
	@include media_1300 {
		height: 231px;
	}
}

.video-player {
	width: 100%;
	height: 100%;
	
	iframe {
		width: 100%;
		height: 100%;
	}
}