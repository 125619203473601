.btn-wrap {
	width: 100%;
	
	&._left {
		text-align: left;
	}
	
	&._right {
		text-align: right;
	}
	
	&._center {
		text-align: center;
	}
}

.btn-style {
	$r: &;
	position: relative;
	display: inline-block;
	cursor: pointer;
	background: transparent;
	max-width: 100%;
	border-radius: 100px;
	overflow: hidden;
	color: $green;
	border: 2px solid $green;
	transition: $default-transition;
	z-index: 5;
	
	p, br {
		display: none;
	}
	
	&__wrapper {
		position: relative;
		display: flex;
		justify-content: center;
		align-items: center;
		overflow: hidden;
		text-align: center;
		padding: 0 33px;
		height: 46px;
		width: 100%;
	}
	
	&__gradient {
		@include absolute(50%, auto, auto, 50%);
		transform: translate(-50%, -50%);
		@include rh();
		width: 100%;
		z-index: 1;
		pointer-events: none;
		
		&::before {
			content: '';
			display: block;
			@include absolute();
			width: 100%;
			height: 100%;
			transition: $default-transition-3;
			background: $gradient4;
		}
	}
	
	&__label {
		position: relative;
		z-index: 2;
	}
	
	&:hover {
		color: $dark;
		border-color: $dark;
		
		&._gradient {
			background: transparent;
			color: $white;
		}
		
		#{$r}__gradient {
			&::before {
				transform: rotate(-180deg);
			}
		}
	}
	
	&._gradient {
		box-shadow: 0 10px 20px rgba(52, 146, 126, 0.34);
		color: $white;
		border: none;
		
		#{$r}__wrapper {
			padding: 0 100px;
			height: 64px;
		}
	}
	
	&._arrow {
		#{$r}__label {
			&::after {
				content: '';
				width: 8px;
				height: 12px;
				margin-left: 10px;
				display: inline-block;
				vertical-align: middle;
				@extend .arrow-right;
			}
		}
	}
	
	&._gray {
		border-color: $gray2;
		color: $green;
		
		&:hover {
			border-color: $green;
			color: $green;
		}
	}
	
	&._white {
		border-color: $white;
		color: $white;
		
		&:hover {
			border-color: $white;
			background: $white;
			color: $green;
		}
	}
	
	
	
	@include media_1700 {
	
	}
	
	@include media_1300 {
		&__wrapper {
			padding: 0 30px;
			height: 40px;
		}
		
		&._gradient {
			#{$r}__wrapper {
				padding: 0 70px;
				height: 60px;
			}
		}
	}
	
	@include media_1000 {
		&__wrapper {
			padding: 0 25px;
			height: 38px;
		}
		
		&._gradient {
			#{$r}__wrapper {
				padding: 0 50px;
				height: 50px;
			}
		}
	}
	
	@include media_700 {
		&__wrapper {
			padding: 0 20px;
			height: 33px;
		}
		
		&._gradient {
			#{$r}__wrapper {
				padding: 0 40px;
				height: 45px;
			}
		}
	}
}

.cross-btn {
	position: absolute;
	width: 40px;
	height: 40px;
	border-radius: 50%;
	background: $green;
	left: auto;
	top: 0;
	right: 0;
	cursor: pointer;
	z-index: 10;
	transition: $default-transition;

	&::before,
	&::after {
		content: '';
		@include absolute();
		width: 16px;
		height: 3px;
		border-radius: 3px;
		background: $white;
		transform-origin: 50% 50%;
		transition: $default-transition;
	}
	
	&::before {
		transform: rotate(-45deg);
	}
	
	&::after {
		transform: rotate(45deg);
	}

	&:hover {
		background: $white;

		&::before, &::after {
			background: $green;
		}

	}
	
	@include media_500 {
		width: 35px;
		height: 35px;
	}
}

.close-button {
	@extend .cross-btn;
}