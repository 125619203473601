.partners {

}

.partners-slider {
	position: relative;
	width: calc(100% + 96px);
	left: -48px;
	display: flex;
	justify-content: flex-start;
	align-items: flex-start;
	
	&.slick-initialized {
		flex-direction: column;
		align-items: center;
		justify-content: flex-start;
	}
	
	.slick-list {
		width: 100%;
	}
	
	&__item {
		width: calc(100% / 7);
		padding: 0 48px;
	}
	
	&__img-wrap {
		position: relative;
		width: 100%;
		@include rh;
		margin-left: auto;
		margin-right: auto;
		max-width: 140px;
		
		svg {
			display: block;
			@include absolute;
			max-width: 100%;
			max-height: 100%;
		}
	}
	
	@include media_1700 {
		width: calc(100% + 60px);
		left: -30px;
		
		&__item {
			padding: 0 30px;
		}
	}
	
	@include media_1500 {
		width: calc(100% + 40px);
		left: -20px;
		
		&__item {
			width: calc(100% / 6);
			padding: 0 20px;
		}
	}
	
	@include media_1300 {
		&__item {
			width: calc(100% / 5);
		}
	}
	
	@include media_1000 {
		width: calc(100% + 20px);
		left: -10px;
		
		&__item {
			padding: 0 10px;
		}
	}
	
	@include media_700 {
		&__item {
			width: calc(100% / 4);
		}
	}
	
	@include media_500 {
		&__item {
			width: calc(100% / 3);
		}
	}
}