.search {
	width: 100%;
	
	&__form {
		position: relative;
		width: 100%;
	}
	
	&__button {
		@include absolute(0,0,0,auto);
		height: 100%;
		width: 60px;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		background: transparent;
		border: none;
		cursor: pointer;
		z-index: 2;
		
		&::before {
			content: '\e90a';
			font-family: $icomoon;
			font-size: 18px;
			line-height: 1;
			display: block;
			color: $green;
			transition: $default-transition;
		}
		
		&:hover {
			&::before {
				color: $dark;
			}
		}
	}
	
	.input-style {
		margin-bottom: 0;
		z-index: 1;
		
		&__input {
			padding-right: 60px;
			color: $dark;
			border-radius: 30px;
			background: $white;
		}
	}
	
	&._shadow {
		.input-style {
			&__input {
				box-shadow: $box-shadow-5;
			}
		}
	}
	
	&._gray-icon & {
		&__button {
			&::before {
				color: $gray6;
			}
			
			&:hover {
				&::before {
					color: $green;
				}
			}
		}
	}
	
	@include media_900 {
		.input-style {
			&__input {
				height: 46px;
			}
		}
		
		&__button {
			width: 46px;
		}
	}
	
	@include media_700 {
		.input-style {
			&__input {
				height: 42px;
			}
		}
		
		&__button {
			width: 42px;
		}
	}
}
