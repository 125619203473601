.blog-nav {
	position: relative;
	width: calc(100% + 96px);
	left: -48px;
	display: flex;
	justify-content: flex-start;
	align-items: stretch;
	flex-wrap: wrap;
	
	&__link {
		position: relative;
		width: calc(50% - 96px);
		margin-left: 48px;
		margin-right: 48px;
		padding: 26px 50px;
		border-radius: 20px;
		background: $white;
		display: flex;
		align-items: center;
		justify-content: flex-end;
		color: $dark;
		transition: $default-transition;
		@include shadow-substrate($box-shadow-2, 20px);
		
		&::before {
			transition: $default-transition;
		}
		
		&:hover {
			color: $green;
			
			&::before {
				box-shadow: $box-shadow-3;
			}
		}
		
		&::after {
			font-family: $icomoon;
			font-size: 20px;
			line-height: 1;
			font-weight: 100;
			color: $green;
			flex: 0 0 auto;
		}
		
		&_prev {
			text-align: left;
			flex-direction: row-reverse;
			
			&::after {
				content: '\e903';
				margin-right: 30px;
			}
		}
		
		&_next {
			text-align: right;
			
			&::after {
				content: '\e904';
				margin-left: 30px;
			}
		}
	}
	@include media_1500 {
		width: calc(100% + 90px);
		left: -45px;
		
		&__link {
			width: calc(50% - 90px);
			margin-left: 45px;
			margin-right: 45px;
			padding: 26px 30px;
		}
	}
	
	@include media_1300 {
		width: calc(100% + 54px);
		left: -27px;
		
		&__link {
			width: calc(50% - 54px);
			margin-left: 27px;
			margin-right: 27px;
			padding: 26px 20px;
			
			&_prev {
				&::after {
					margin-right: 15px;
				}
			}
			
			&_next {
				&::after {
					margin-left: 15px;
				}
			}
		}
	}
	
	@include media_900 {
		width: 100%;
		left: 0;
		
		&__link {
			width: 100%;
			margin-left: 0;
			margin-right: 0;
			margin-bottom: 20px;
			padding: 20px 30px;
			
			&:last-child {
				margin-bottom: 0;
			}
			
			&_prev {
				&::after {
					margin-right: 10px;
				}
			}
			
			&_next {
				&::after {
					margin-left: 10px;
				}
			}
		}
	}
	
	@include media_700 {
		&__link {
			padding: 20px 10px;
		}
	}
}