$white: #fff;
$black:#000;

$green: #43BDA7;
$green2: #53B457;
$green3: #34927E;
$dark: #2F2E41;
$gray: #C8D1E2;
$gray2: #DBE1ED;
$gray3: #B0B4BC;
$gray4: #FAFBFD;
$gray5: #F2F2F2;
$gray6: #BDBDBD;
$gray7: #e6e6e6;

$gradient1: linear-gradient(180deg, #FAFBFD 0%, #DAE0EC 59.9%, #A3E9CF 97.4%);
$gradient2: linear-gradient(180deg, #FAFBFD 0%, #DAE0EC 100%);
$gradient3: linear-gradient(90deg, #63C58C 0%, #41BCA8 100%);
$gradient4: linear-gradient(90deg, #53B457 0%, #34927E 100%);
$gradient5: linear-gradient(180deg, #e8eff1 0%, rgba(232, 239, 241, 0) 100%);

$box-shadow-1: 0px 10px 20px rgba(110, 200, 237, 0.8);
$box-shadow-2: 0px 0px 100px rgba(218, 230, 235, 0.5);
$box-shadow-3: 0px 0px 100px rgba(67,189,167, 0.3);
$box-shadow-4: 0px 10px 20px rgba(218, 230, 235, 0.5);
$box-shadow-5: 0px 0px 100px rgba(218, 230, 235, 0.8);