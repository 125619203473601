.title-block {
	position: relative;
	display: block;
	text-align: center;
	
	&__btn-wrap {
		@include absolute(auto,0,0,auto);
		
		.btn-style {
			display: block;
		}
	}
	
	&._left {
		text-align: left;
	}
	
	&._right {
		text-align: right;
	}
	
	@include media_1000 {
		&__btn-wrap {
			position: relative;
			display: flex;
			justify-content: center;
			margin-top: 20px;
		}
	}
}
