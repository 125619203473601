.form-style {
    position: relative;
    
    span[class$="tip"] {
        margin-top: 5px;
    }

    &__input-list {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: flex-end;
        
        & > p {
            display: none;
        }
    }

    &__input-list_2-col {
        .select-module,
        .input-style {
            width: calc(50% - 10px);

            &_textarea {
                width: 100%;
            }
        }
    }
    
    @include media_700 {
        &__input-list_2-col {
            .select-module,
            .input-style {
                width: 100%;
            }
        }
    }
}
