.services {
	
	&__wrap {
		background: $gradient2;
	}
}

.services-list {
	position: relative;
	width: calc(100% + 20px);
	left: -10px;
	display: flex;
	align-items: stretch;
	justify-content: flex-start;
	flex-wrap: wrap;
	margin-top: -30px;
	
	&__item {
		position: relative;
		min-height: 220px;
		border: 2px solid $gray;
		border-radius: 10px;
		color: $dark;
		width: calc(50% - 20px);
		margin: 60px 10px 40px;
		display: flex;
		align-items: center;
		justify-content: flex-end;
		transition: $default-transition;
		
		&:hover {
			background: $white;
			box-shadow: 0 0 40px rgba(13, 101, 115, 0.15);
		}
	}
	
	&__img-wrap {
		display: block;
		width: calc(100% / 6 * 3 - 78px);
		max-width: 300px;
		@include absolute(-30px, auto, auto, 38px);
		@include rh;
		
		svg {
			@include absolute(0,0,auto);
			display: block;
			max-width: 100%;
			max-height: 100%;
		}
	}
	
	&__text-wrap {
		padding: 20px 70px 20px 0;
		width: calc(100% / 6 * 3 - 10px);
		display: block;
	}
	
	@include media_1700 {
		&__item {
			min-height: 170px;
			margin: 60px 10px 0;
		}
		
		&__text-wrap {
			padding: 20px 20px 20px 0;
		}
	}
	
	@include media_1300 {
		margin-top: -20px;
		
		&__item {
			min-height: 140px;
			margin: 40px 10px 20px;
		}
		
		&__img-wrap {
			width: calc(100% / 6 * 3 - 20px);
			max-width: 200px;
			top: -20px;
			left: -10px;
		}
	}
	
	@include media_1000 {
		&__item {
			min-height: 0;
			padding-top: 50px;
			margin: 120px 10px 0;
		}
		
		&__img-wrap {
			top: auto;
			left: 0;
			right: 0;
			width: 150px;
			bottom: calc(100% - 60px);
		}
		
		&__text-wrap {
			padding: 20px;
			width: 100%;
			text-align: center;
		}
	}
	
	@include media_700 {
		&__item {
			width: calc(100% - 20px);
			min-height: 0;
			padding-top: 50px;
			margin: 100px 10px 0;
		}
		
		&__img-wrap {
			width: 130px;
		}
	}
}

@keyframes service-1-column {
	0% {
		transform: scaleY(1);
	}
	
	50% {
		transform: scaleY(1);
	}
	
	75% {
		transform: scaleY(1.1);
	}
	
	100% {
		transform: scaleY(1);
	}
}

@keyframes service-1-diagram {
	0% {
		transform: translate3d(0, 0, 0);
	}
	
	50% {
		transform: translate3d(0, 0, 0);
	}
	
	75% {
		transform: translate3d(0, -6%, 0);
	}
	
	100% {
		transform: translate3d(0, 0, 0);
	}
}

@keyframes service-1-pawn {
	0% {
		transform: translate3d(0, 0, 0);
	}
	
	50% {
		transform: translate3d(0, 0, 0);
	}
	
	75% {
		transform: translate3d(0, -26%, 0);
	}
	
	100% {
		transform: translate3d(0, 0, 0);
	}
}
@keyframes service-1-like {
	0% {
		transform: translate3d(0, 0, 0);
	}
	
	50% {
		transform: translate3d(0, 0, 0);
	}
	
	75% {
		transform: translate3d(0, -31%, 0);
	}
	
	100% {
		transform: translate3d(0, 0, 0);
	}
}

$serviceColumnsDuration: 5s;
$serviceColumnsDelay: .5s;

.service-1-column {
	transform-box: fill-box;
	transform-origin: 50% 100%;
	animation: service-1-column $serviceColumnsDuration ease-in-out infinite;
}

.service-1-diagram {
	transform-box: fill-box;
	animation: service-1-diagram $serviceColumnsDuration ease-in-out infinite;
}

.service-1-pawn {
	transform-box: fill-box;
	animation: service-1-pawn $serviceColumnsDuration ease-in-out infinite;
}

.service-1-like {
	transform-box: fill-box;
	animation: service-1-like $serviceColumnsDuration ease-in-out infinite;
}

.service-1-pawn,
.service-1-column-2 {
	animation-delay: $serviceColumnsDelay;
}
.service-1-like,
.service-1-column-3 {
	animation-delay: $serviceColumnsDelay*2;
}

.service-1-column-4 {
	animation-delay: $serviceColumnsDelay*3;
}

.service-1-column-5 {
	animation-delay: $serviceColumnsDelay*4;
}