.faq {

}

.faq-table {
	display: flex;
	align-items: flex-start;
	justify-content: space-between;
	
	&__col {
		&_left {
			width: calc(100% / 10 * 5 - 10px);
		}
		
		&_right {
			width: calc(100% / 10 * 4 - 10px);
		}
	}
	
	&__img-wrap {
		position: relative;
		width: 100%;
		@include rh(673/638);
		
		svg {
			@extend .abs-fit-svg;
		}
	}
	
	@include media_1700 {
	
	}
	
	@include media_1300 {
	
	}
	
	@include media_1000 {
		flex-direction: column;
		
		&__col {
			width: 100%;
			
			&_right {
				margin-top: 30px;
			}
		}
		
		&__img-wrap {
			max-width: 300px;
			margin-left: auto;
			margin-right: auto;
		}
	}
	
	@include media_700 {
		&__img-wrap {
			max-width: 200px;
		}
	}
	
	@include media_500 {
		&__col {
			&_right {
				margin-top: 20px;
			}
		}
	}
}

.faq-list {
	$r: &;
	
	&__item {
		width: 100%;
		border-bottom: 2px solid $gray2;
	}
	
	&__title-wrap {
		display: flex;
		justify-content: space-between;
		align-items: center;
		cursor: pointer;
		
		&::before {
			content: '';
			display: block;
			width: 16px;
			height: 12px;
			@extend .arrow-down;
			transform-origin: 50% 50%;
			transition: $default-transition;
		}
		
		&:hover {
			#{$r}__title {
				color: $dark;
			}
		}
		
		&._active {
			&::before {
				transform: scaleY(-1);
			}
		}
	}
	
	&__title {
		color: $green;
		width: calc(100% - 46px);
		transition: $default-transition;
	}
	
	&__text {
		display: none;
	}
	
	@include media_1700 {
	
	}
	
	@include media_1300 {
		&__title {
			width: calc(100% - 35px);
		}
	}
	
	@include media_1000 {
	
	}
	
	@include media_700 {
	
	}
}


@keyframes faq-tree {
	0% {
		transform: skewX(-3deg);
	}
	
	100% {
		transform: skewX(3deg);
	}
}

.faq-tree {
	transform-origin: 50% 100%;
	transform-box: fill-box;
	animation: faq-tree 1.25s ease-in-out infinite alternate;
	transform: skewX(-3deg);
}


@keyframes faq-dollar {
	0% {
		transform: translate3d(0,0,0);
	}
	
	50% {
		transform: translate3d(0,-7%,0);
	}
	
	100% {
		transform: translate3d(0,0,0);
	}
}

.faq-dollar {
	transform-box: fill-box;
	animation: faq-dollar 2s ease-in-out infinite;
}

$faqManAnimationsDuration: 5s;

@keyframes faq-man-head {
	0% {
		transform: rotate(0);
	}
	
	40% {
		transform: rotate(0);
	}
	
	60% {
		transform: rotate(-15deg);
	}
	
	80% {
		transform: rotate(-15deg);
	}
	
	100% {
		transform: rotate(-0);
	}
}

.faq-man-head {
	transform-box: fill-box;
	transform-origin: 50% 100%;
	animation: faq-man-head $faqManAnimationsDuration ease-in-out infinite;
}



@keyframes faq-man-left-hand {
	0% {
		transform: rotate(0);
	}
	
	40% {
		transform: rotate(0);
	}
	
	60% {
		transform: rotate(15deg);
	}
	
	80% {
		transform: rotate(15deg);
	}
	
	100% {
		transform: rotate(-0);
	}
}

.faq-man-left-hand {
	transform-box: fill-box;
	transform-origin: 95% 5%;
	animation: faq-man-left-hand $faqManAnimationsDuration ease-in-out infinite;
}

@keyframes faq-man-right-hand {
	0% {
		transform: rotate(0);
	}
	
	40% {
		transform: rotate(0);
	}
	
	60% {
		transform: rotate(10deg);
	}
	
	80% {
		transform: rotate(10deg);
	}
	
	100% {
		transform: rotate(-0);
	}
}

.faq-man-right-hand {
	transform-box: fill-box;
	transform-origin: 75% 10%;
	animation: faq-man-right-hand $faqManAnimationsDuration ease-in-out infinite;
}

@keyframes faq-map {
	0% {
		transform: translate3d(0,0,0);
	}
	
	40% {
		transform: translate3d(0,0,0);
	}
	
	60% {
		transform: translate3d(0,-15%,0);
	}
	
	80% {
		transform: translate3d(0,-15%,0);
	}
	
	
	100% {
		transform: translate3d(0,0,0);
	}
}

.faq-map {
	transform-box: fill-box;
	animation: faq-map $faqManAnimationsDuration ease-in-out infinite;
}