@charset "UTF-8";

// Default Variables

// Slick icon entity codes outputs the following
// "\2190" outputs ascii character "←"
// "\2192" outputs ascii character "→"
// "\2022" outputs ascii character "•"

$slick-font-path: "./fonts/" !default;
$slick-font-family: "slick" !default;
$slick-loader-path: "./" !default;
$slick-arrow-color: white !default;
$slick-dot-color: black !default;
$slick-dot-color-active: $slick-dot-color !default;
$slick-prev-character: "\2190" !default;
$slick-next-character: "\2192" !default;
$slick-dot-character: "\2022" !default;
$slick-dot-size: 6px !default;
$slick-opacity-default: 0.75 !default;
$slick-opacity-on-hover: 1 !default;
$slick-opacity-not-active: 0.25 !default;

@function slick-image-url($url) {
    @if function-exists(image-url) {
        @return image-url($url);
    }
    @else {
        @return url($slick-loader-path + $url);
    }
}

@function slick-font-url($url) {
    @if function-exists(font-url) {
        @return font-url($url);
    }
    @else {
        @return url($slick-font-path + $url);
    }
}

/* Slider */

.slick-list {
    .slick-loading & {
        //background: #fff slick-image-url("ajax-loader.gif") center center no-repeat;
    }
}

/* Icons */
@if $slick-font-family == "slick" {
    @font-face {
        font-family: "slick";
        src: slick-font-url("slick.eot");
        src: slick-font-url("slick.eot?#iefix") format("embedded-opentype"), slick-font-url("slick.woff") format("woff"), slick-font-url("slick.ttf") format("truetype"), slick-font-url("slick.svg#slick") format("svg");
        font-weight: normal;
        font-style: normal;
    }
}

/* Arrows */

.slick-prev,
.slick-next {
    position: absolute;
    display: block;
    cursor: pointer;
    background: transparent;
    color:transparent;
    top: 0%;
    border: none;
    outline: none;
    z-index: 3;
    opacity:1;
    font-size:0;
    line-height:0;
    &:hover, &:focus {
        outline: none;
    }
}

.slick-prev {
    right: 58px;
    &:before {
        content:"\f104";
    }
}

.slick-next {
    right: 6px;
    &:before {
        content: "\f105";
    }
}

/* Dots */

.slick-dotted.slick-slider {
}

.slick-dots {
    position: absolute;
    bottom: 0;
    list-style: none;
    display: block;
    text-align: center;
    padding: 0;
    margin: 0;
    width: 100%;
    z-index: 2;
	padding-bottom: 20px;
    li {
        position: relative;
        display: inline-block;
        padding: 0;
        cursor: pointer;
        button {
            border: 0;
            background: transparent;
            color:transparent;
            display: block;
            cursor: pointer;
            outline: none;
            padding: 10px;
            line-height:0;
            &:before{
                width: 14px;
                height: 14px;
                background-color: #ccc;
                border-radius:50%;
                content:"";
                display:block;
                transition:0.3s ease-in-out;
            }
            &:hover:before{
                // background-color:$green;
            }
        }
        &.slick-active button:before{
            // background-color:$green;
        }
    }
}
