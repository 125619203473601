.content-img {
	position: relative;
	width: 100%;
	height: auto;
	border-radius: 10px;
	overflow: hidden;
	@extend .mb100;
	
	&:last-child {
		margin-bottom: 0;
	}
}