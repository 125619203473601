$bar-width: 18px;
$bar-height: 3px;
$bar-spacing: 3px;
$bar-color: $white;
$active-color: $white;

.hamburger-wrapper {
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 0;
	cursor: pointer;
	transition: $default-transition;
	width: 46px;
	height: 46px;
	border-radius: 50%;
	background: $green;
	z-index: 2;

	&__label {
		margin-left: 10px;
	}

	&__hamburger {
		width: $bar-width;
		height: $bar-height * 3 + $bar-spacing * 2;
		position: relative;
		z-index: 1;
		cursor: pointer;
		display: block;
	}

	&__bar {
		width: $bar-width;
		height: $bar-height;
		background: $bar-color;
		border-radius: 3px;
		position: relative;
		transform: translateY($bar-height + $bar-spacing);
		background: $bar-color;
		margin: auto auto auto 0;
		transition: all 0ms 300ms;
		will-change: transform;

		&::before,
		&::after {
			width: inherit;
			height: inherit;
			background: $bar-color;
			border-radius: 3px;
			will-change: transform;
		}

		&:before {
			content: "";
			position: absolute;
			left: 0;
			bottom: $bar-spacing + $bar-height;
			background: $bar-color;
			transition: width 0ms 300ms, bottom 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1), transform 300ms cubic-bezier(0.23, 1, 0.32, 1);
		}

		&:after {
			content: "";
			position: absolute;
			left: 0;
			top: $bar-spacing + $bar-height;
			background: $bar-color;
			transition: width 0ms 300ms, top 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1), transform 300ms cubic-bezier(0.23, 1, 0.32, 1);
		}
	}

	@include media_700 {
		$bar-width: 16px;
		$bar-spacing: 2px;
		width: 40px;
		height: 40px;

		&__hamburger {
			width: $bar-width;
			height: $bar-height * 3 + $bar-spacing * 2;
		}

		&__bar {
			transform: translateY($bar-height + $bar-spacing);
			width: $bar-width;
		}

		&__bar {
			&:before {
				bottom: $bar-height + $bar-spacing;
			}

			&:after {
				top: $bar-height + $bar-spacing;
			}
		}
	}
}


.header._active {
	.hamburger-wrapper {
		&__bar {
			background-color: transparent;

			&::before,
			&::after {
				background: $active-color;
			}

			&:after {
				top: 0;
				transform: rotate(45deg);
				transition: width 0ms 300ms, top 300ms cubic-bezier(0.23, 1, 0.32, 1), transform 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1);
			}

			&:before {
				bottom: 0;
				transform: rotate(-45deg);
				transition: width 0ms 300ms, bottom 300ms cubic-bezier(0.23, 1, 0.32, 1), transform 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1);
			}
		}
	}
}

