/*
  This mixin can be used to set the object-fit:
  @include object-fit(contain);

  or object-fit and object-position:
  @include object-fit(cover, top);
*/
@mixin object-fit($fit: fill, $position: null){
  -o-object-fit: $fit;
     object-fit: $fit;
  @if $position {
	  -o-object-position: $position;
	     object-position: $position;
  	font-family: 'object-fit: #{$fit}; object-position: #{$position}';
  } @else {
  	font-family: 'object-fit: #{$fit}';
  }
}
