.footer {
    position: relative;
    z-index: 1;
    
    &__row {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex-wrap: wrap;
    }
    
    &__copyright {
        margin-right: 50px;
    }

    &__description {
        width: calc(100% / 12 * 6);
        color: $gray3;
        padding-right: 60px;
    }
    
    &__logo-wrap {
        width: calc(100% / 12 * 2);
        padding-right: 20px;
        padding-bottom: 20px;
    }
    
    &__soc-wrap {
        width: calc(100% / 12 * 4);
    }
    
    &__link {
        margin-right: 50px;
        
        &:last-child {
            margin-right: 0;
        }
    }
    
    
    @include media_1700 {
        &__description {
            width: calc(100% / 12 * 7);
            padding-right: 40px;
        }
        
        &__soc-wrap {
            width: calc(100% / 12 * 3);
        }
    }
    
    @include media_1300 {
        &__description {
            padding-right: 30px;
        }
    }
    
    @include media_1000 {
        &__logo-wrap {
            width: calc(100% / 12 * 4);
            padding-bottom: 0;
        }
        &__description {
            width: 100%;
            padding-right: 0;
            margin-top: 40px;
        }
        &__soc-wrap {
            width: 100%;
            margin-top: 40px;
        }
        &__copyright,
        &__link {
            margin-right: 30px;
        }
    }
    
    @include media_700 {
        &__logo-wrap {
            width: 100%;
            padding-right: 0;
        }
        &__description {
            width: 100%;
            margin-top: 30px;
        }
        &__soc-wrap {
            margin-top: 30px;
        }
    
    
        &__copyright {
            margin-right: 0;
        }
        
        &__links-wrap {
            margin-top: 30px;
            width: 100%;
        }
    }
    @include media_500 {
        &__links-wrap {
            display: flex;
            flex-direction: column;
            
        }
        &__link {
            margin-bottom: 10px;
            margin-right: 0;
            
            &:last-child {
                margin-bottom: 0;
            }
        }
    }
}
